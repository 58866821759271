
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import QuoteFormCalculations from '@/components/QuoteFormCalculations.vue'
import QuotesAutomatedPricingLayout from '@/components/QuotesAutomatedPricingLayout.vue'
import QuoteFormTripPaymentAdjustments from '@/components/QuoteFormTripPaymentAdjustments.vue'
import QuoteFormTripPaymentOptions from '@/components/QuoteFormTripPaymentOptions.vue'

import { deepClone } from '@/utils/deepClone'
import { currencyFilter } from '@/utils/currency'
import { SplitFeatureFlag } from '@/utils/enum'
import { validateTripFormGroups } from '@/utils/validators'
import { scrubQuoteForAPI, isMarketplaceQuote, shouldAutoPriceQuote } from '@/utils/quoteUtils'
import { EventBus } from '@/utils/event-bus'
import { ChargeTypeId } from '@/utils/enum'
import {
  MarketRateType,
  PaymentMethodType,
  PaymentType,
  TripChargeType,
} from '@/models/dto'
import { getDatetimeFromDateAndTimeStrings } from '@/components/quoteFormhelpers'

@Component({
  components: {
    QuoteFormCalculations,
    QuotesAutomatedPricingLayout,
    QuoteFormTripPaymentAdjustments,
    QuoteFormTripPaymentOptions,
  }
})
export default class QuoteFormTripPaymentV2 extends Vue {
  @Prop({ type: Object, required: true }) readonly tripData: any
  @Prop({ type: Object, required: true }) readonly quote: any
  @Prop({ type: String, default: '' }) readonly mode: string
  @Prop({ type: Number, required: true }) readonly tripIndex: number
  @Prop({ type: Boolean, default: false }) readonly canOpenCharterUpQuote: boolean
  @Prop({ type: Boolean, default: false }) readonly calculatingEstimations: boolean
  @Prop({ type: String, default: '' }) readonly validationKey: string
  @Prop({ type: Number, default: 1 }) readonly exchangeRate: number
  @Prop({ type: String, default: '' }) readonly estimatedTimeLabel: string
  @Prop({ type: String, default: '' }) readonly deadMileLabel: string
  @Prop({ type: String, default: '' }) readonly liveMileLabel: string
  @Prop({ type: Boolean, default: false }) readonly clearPricingTable: boolean
  @Prop({ type: Boolean, default: false }) readonly refreshPricing: boolean
  @Prop({ type: Boolean, default: false }) readonly showMarketplaceFeatures: boolean
  @Prop({ type: Object, default: null }) readonly customerAccountDefaults: any
  @Prop({ type: String, default: 'USD' }) readonly currencyType: string
  @Prop({ type: Array, default: () => [] }) readonly paymentMethodTypes: PaymentMethodType[]
  @Prop({ type: Array, default: () => [] }) readonly paymentTypes: PaymentType[]
  @Prop({ type: Array, default: () => [] }) readonly chargeTypes: TripChargeType[]
  @Prop({ type: Array, default: () => [] }) readonly rateTypes: MarketRateType[]

  formSubmitted: boolean = false
  hasNotUsedAutoPrice: boolean = true
  managerOverrideUsed: boolean = false
  isMarkupCalculationUpdateEnabled: boolean = false
  pricingDebounce: NodeJS.Timeout = null
  currencyFilter = currencyFilter

  get isMarketplaceQuote(): boolean {
    return isMarketplaceQuote(this.quote)
  }

  get showNetMarketplaceAdjustment(): boolean {
    return this.hasMarketplaceDiscount &&
      this.hasMarketplaceMarkup &&
      this.isMarkupCalculationUpdateEnabled
  }

  get isModeView(): boolean {
    return this.mode === 'view'
  }

  get isModeAdd(): boolean {
    return this.mode === 'add'
  }

  get currentTrip(): any {
    return this.$store.getters['quoteForm/getCurrentTrip']
  }

  get processingFeePercentage(): number {
    if (
      this.tripData?.processingFeePercentage === null ||
      this.tripData?.processingFeePercentage === undefined
    ) {
      return this.currentTrip?.processingFeePercentage
    }
    return this.tripData?.processingFeePercentage
  }

  get charges(): any[] {
    return this.tripData?.charges || this.currentTrip?.charges
  }

  get depositPercentage(): number {
    if (
      this.tripData?.depositPercentage === null ||
      this.tripData?.depositPercentage === undefined
    ) {
      return parseInt(this.currentTrip?.depositPercentage)
    }
    return parseInt(this.tripData?.depositPercentage)
  }

  get isPrePricingMode(): boolean {
    return !shouldAutoPriceQuote(this.quote)
  }

  get displayAutomatedPricing(): boolean {
    return (
      !this.isPrePricingMode &&
      this.hasNotUsedAutoPrice &&
      !this.isModeView &&
      this.canOpenCharterUpQuote
    )
  }

  get total(): number {
    const tripTotal =
      this.processingFeeTotalForDisplay +
      this.marketplaceDiscountTotalForDisplay +
      this.marketplaceMarkupTotal +
      this.subtotal
    return tripTotal
  }

  get subtotal(): number {
    const chargeTotal = this.charges.reduce(
      (total, charge) => total + parseFloat(charge.amount || 0),
      0
    )
    return chargeTotal
  }

  get marketplaceMarkupPercent(): number {
    if (this.tripData.marketplaceMarkupPercent > 0) {
      return this.tripData.marketplaceMarkupPercent
    }
    return 0
  }

  get marketplaceMarkupTotal(): number {
    if (this.marketplaceMarkupPercent > 0) {
      const marketplaceMarkupCharge =
        this.marketplaceMarkupPercent *
        0.01 *
        this.subtotal
      return marketplaceMarkupCharge
    }
    return 0
  }

  get marketplaceDiscountPercent(): number {
    if (this.tripData.marketplaceDiscountPercent > 0) {
      return this.tripData.marketplaceDiscountPercent
    }
    return 0
  }

  get marketplaceDiscountTotalForDisplay(): number {
    if (this.marketplaceDiscountPercent > 0) {
      const discountBaseForDisplay = this.isMarkupCalculationUpdateEnabled
          ? this.subtotal + this.processingFeeTotalForDisplay + this.marketplaceMarkupTotal
          : this.subtotal + this.processingFeeTotalForDisplay
        const marketplaceDiscountTotalForDisplay =
          (this.marketplaceDiscountPercent / 100) * discountBaseForDisplay * -1
      return marketplaceDiscountTotalForDisplay
    }
    return 0
  }

  get marketplaceDiscountTotalForSave(): number {
    if (this.marketplaceDiscountPercent > 0) {
      return this.marketplaceDiscountPercent * 0.01 * (this.subtotal + this.processingFeeTotalForSave) * -1
    }
    return 0
  }

  get processingFeeTotalForDisplay(): number {
    const processingFeeForDisplay = this.isMarkupCalculationUpdateEnabled
      ? this.processingFeePercentage * 0.01 * (this.subtotal + this.marketplaceMarkupTotal)
      : this.processingFeePercentage * 0.01 * this.subtotal


    return processingFeeForDisplay
  }

  get processingFeeTotalForSave(): number {
    return this.processingFeePercentage * 0.01 * this.subtotal
  }

  get marketplaceBaseAmount(): number {
    return this.subtotal + this.processingFeeTotalForDisplay
  }

  get totalBeforeMarkupAndDiscount(): number {
    return this.subtotal * (1 + this.processingFeePercentage * 0.01)
  }

  get netMarketplaceAdjustmentPercent(): string {
    return (((this.total - this.totalBeforeMarkupAndDiscount) / this.totalBeforeMarkupAndDiscount) * 100).toFixed(2)
  }

  get netMarketplaceAdjustmentAmount(): number {
    return this.total - this.totalBeforeMarkupAndDiscount
  }

  get amenitiesChargeTotal(): number {
    const amenitiesCharges = this.charges.filter(
      (charge) => charge?.chargeType?.id == ChargeTypeId.Amenities
    )
    const amenitiesChargesTotal = amenitiesCharges.reduce(
      (total, charge) => total + parseFloat(charge.amount || 0),
      0
    )
    return amenitiesChargesTotal
  }

  get hasMarketplaceMarkup(): boolean {
    return this.marketplaceMarkupPercent > 0
  }

  get hasMarketplaceDiscount(): boolean {
    return this.marketplaceDiscountPercent > 0
  }

  get shouldHitPricingEngine(): boolean {
    return (
      this.isMarketplaceQuote &&
      !this.managerOverrideUsed &&
      this.quote.trips[this.tripIndex].tripType.id &&
      this.quote.trips[this.tripIndex]?.requiredVehicles[0]?.quantity &&
      this.quote.trips[this.tripIndex]?.requiredVehicles[0]?.vehicleType.id
    )
  }

  @Watch('total')
  onTotalChanged(total: number): void {
    const updatedTripData = {
      ...this.tripData,
      total,
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
    EventBus.$emit('set-trip-total', total)
  }

  @Watch('marketplaceMarkupPercent')
  onMarketplaceMarkupPercentChanged(): void {
    const updatedTripData = {
      ...this.tripData,
      marketplaceMarkupCharge: this.marketplaceMarkupTotal,
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
  }

  @Watch('marketplaceDiscountPercent')
  onMarketplaceDiscountPercentChanged(): void {
    const updatedTripData = {
      ...this.tripData,
      marketplaceDiscountCharge: this.marketplaceDiscountTotalForSave,
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
  }

  @Watch('processingFeePercentage')
  onProcessingFeePercentageChanged(): void {
    const updatedTripData = {
      ...this.tripData,
      processingFeeCharge: this.processingFeeTotalForSave,
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
    EventBus.$emit('set-processing-fee-charge', this.processingFeeTotalForSave)
  }

  @Watch('marketplaceMarkupTotal')
  onMarketplaceMarkupTotalChanged(markupTotal: number): void {
    const updatedTripData = {
      ...this.tripData,
      marketplaceMarkupCharge: markupTotal,
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
  }

  @Watch('marketplaceDiscountTotalForSave')
  onMarketplaceDiscountTotalChanged(discountTotal: number): void {
    const updatedTripData = {
      ...this.tripData,
      marketplaceDiscountCharge: discountTotal,
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
  }

  @Watch('processingFeeTotalForSave')
  onProcessingFeeTotalChanged(processingFeeTotal: number): void {
    const updatedTripData = {
      ...this.tripData,
      processingFeeCharge: processingFeeTotal,
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
    EventBus.$emit('set-processing-fee-charge', processingFeeTotal)
  }

  @Watch('validationKey')
  onValidationKeyChanged(): void {
    this.validateForms()
  }

  @Watch('tripData', { deep: true })
  onTripDataChanged(): void {
    let chargeTotal
    if (this.currentTrip) {
      chargeTotal = this.currentTrip.charges.reduce(
        (total, charge) => total + parseFloat(charge.amount || 0),
        0
      )
      if (!this.currentTrip.charges.length) {
        this.hasNotUsedAutoPrice = true
      }
    } else {
      chargeTotal = this.tripData.charges.reduce(
        (total, charge) => total + parseFloat(charge.amount || 0),
        0
      )
    }
    if (chargeTotal > 0) {
      this.hasNotUsedAutoPrice = false
    }
  }

  @Watch('tripData.passengerCount')
  onPassengerCountChanged(): void {
    this.resetManagerOverridePrice()
  }

  @Watch('tripData.requiredDrivers')
  onRequiredDriversChanged(): void {
    this.resetManagerOverridePrice()
  }

  @Watch('tripData.requiredVehicles', { deep: true })
  onRequiredVehiclesChanged(): void {
    this.resetManagerOverridePrice()
  }

  @Watch('tripData.tripType.id')
  onTripTypeChanged(): void {
    this.resetManagerOverridePrice()
  }

  @Watch('tripData.pricingMarket')
  onPricingMarketChanged(): void {
    this.resetManagerOverridePrice()
  }

  @Watch('quote.pricingMethod')
  onPricingMethodChanged(): void {
    this.resetManagerOverridePrice()
  }

  @Watch('quote.leadsource.id')
  onLeadSourceChanged(): void {
    this.resetManagerOverridePrice()
  }

  @Watch('tripData.stops', { deep: true })
  onStopsChanged(stops): void {
    // Use method from scrubQuoteForAPI to check if at least one stop is valid
    if (stops.some(stop => {
      return !!getDatetimeFromDateAndTimeStrings(stop.pickupDate, stop.pickupTime) || !!getDatetimeFromDateAndTimeStrings(stop.dropoffDate, stop.dropoffTime)
    })) {
      this.resetManagerOverridePrice()
    }
  }

  @Watch('recalculatePricing')
  onRecalculatePricing(): void {
    this.autoSelectThePrice()
  }

  @Watch('refreshPricing', { immediate: true })
  onRefreshPricing(shouldRefreshPricing: boolean): void {
    if (shouldRefreshPricing) {
      this.autoSelectThePrice()
      this.$emit('refresh-pricing')
    }
  }

  async mounted(): Promise<void> {
    this.isMarkupCalculationUpdateEnabled = await this.$store.dispatch(
      'split/isFeatureEnabled', SplitFeatureFlag.MarkupCalculationUpdates
    )
    const updatedTripData = { ...this.tripData }
    if (!this.tripData.rates) {
      updatedTripData.rates = []
    }
    if (!this.tripData.charges || this.tripData?.charges?.length === 0) {
      updatedTripData.charges = [{ chargeType: { id: 1 }, amount: 0 }]
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)

    const chargeTotal = this.tripData.charges.reduce(
      (total, charge) => total + parseFloat(charge.amount || 0),
      0
    )
    if (chargeTotal > 0) {
      this.hasNotUsedAutoPrice = false
    }
  }

  async autoSelectThePrice(): Promise<void> {
    if (this.isPrePricingMode) {
      return
    }
    if (typeof this.pricingDebounce !== 'undefined') {
      clearTimeout(this.pricingDebounce)
    }
    this.pricingDebounce = setTimeout(async () => {
      let quoteClone: any = deepClone(this.quote)
      quoteClone = scrubQuoteForAPI(quoteClone, true)
      const selectedMarket = this.$store.getters['quotes/getSelectedMarket'](this.tripIndex)
      quoteClone.pricingMarket = selectedMarket?.marketId

      // When viewing or editing the quote, don't override existing trip pricing markets
      quoteClone.trips.forEach((trip) => {
        if (!!trip.active) {
          trip.isManuallySelectedPricingMarket =
          ['view', 'edit'].includes(this.mode) || trip.isManuallySelectedPricingMarket
        }
      })

      const selectedGarage = this.$store.getters['quotes/getSelectedGarage'](this.tripIndex)
      quoteClone.pricingGarageId = selectedGarage?.garageId
      const quotePricingResponse = await this.$store.dispatch('quotes/getQuotePricing', quoteClone)
      const quotePricingData = quotePricingResponse.data
      const splitTrips = !!quotePricingData?.quoteShouldBeSplitIntoMultipleTrips
      const vehicleNeededEntireTrip = this.quote.trips[this.tripIndex]
        .vehicleNeededEntireTrip
      this.$store.dispatch('quotes/setShouldSplitTrip', splitTrips && !vehicleNeededEntireTrip)
      const result = quotePricingData?.pricings?.find(
        (pricing) =>
          pricing.autoSelected &&
          pricing.tripId === this.tripIndex &&
          pricing.companyId === this.quote.leadSource.partnerCompanyId
      )
      if (result) {
        const updatedTripData = {
          ...this.tripData,
          pricingMethod: result.pricingMethod,
        }
        EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
        EventBus.$emit('set-quote-pricing-time-label', result.pricingTimeLabel)
        const autoSelectPrice = result.priceHigh
        this.setFare(autoSelectPrice)
      }
    }, 1000)
  }

  setFare(fare: number): void {
    const charges = deepClone(this.charges)
    const baseFare = charges.find(
      (charge) => charge.chargeType.id === ChargeTypeId.BaseFare
    )

    if (!baseFare) {
      const newBaseFare = { chargeType: { id: 1 }, amount: fare }
      charges.push(newBaseFare)
    } else {
      baseFare.amount = fare
    }

    const updatedTripData = {
      ...this.tripData,
      charges,
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
    EventBus.$emit('set-fare', fare)
    this.hasNotUsedAutoPrice = false
  }

  validateForms(): void {
    const formNames = ['payment-select-table']
    validateTripFormGroups.call(this, formNames, 'payment')
  }

  resetManagerOverridePrice(): void {
    this.managerOverrideUsed = false
    if (this.shouldHitPricingEngine) {
      this.autoSelectThePrice()
    }
  }

  revert(): void {
    if (this.isMarketplaceQuote) {
      this.managerOverrideUsed = true
    }
    this.hasNotUsedAutoPrice = true
  }
}
