import { render, staticRenderFns } from "./BidCard.vue?vue&type=template&id=5b602fdb&scoped=true"
import script from "./BidCard.vue?vue&type=script&lang=ts"
export * from "./BidCard.vue?vue&type=script&lang=ts"
import style0 from "./BidCard.vue?vue&type=style&index=0&id=5b602fdb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b602fdb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VChip,VFlex,VLayout,VProgressCircular,VSpacer})
