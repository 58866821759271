
import { AmenityType } from '@/models/dto'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { paidAmenityTypeIds, guaranteedAmenityTypeIds, bestEffortAmenityTypeIds, AmenityTypeId } from '@/utils/enum'
import { quoteFormAmenities } from '@/utils/amenity'
import { deepClone } from '@/utils/deepClone'

@Component({})
export default class QuoteFormAmenities extends Vue {
  @Prop({ type: Number, required: true }) readonly tripIndex: number
  @Prop({ type: Array, default: () => [] }) readonly tripAmenities: AmenityType[]
  @Prop({ type: String, default: '' }) readonly bookedByName: string
  @Prop({ type: Boolean, required: true }) readonly disabled: boolean

  paidAmenitiesList: AmenityType[] = []
  guaranteedAmenitiesList = []
  bestEffortAmenitiesList = []

  get filteredAmenities(): AmenityType[] {
    return this.tripAmenities
      .filter(amenity => ![AmenityTypeId.Bathroom, AmenityTypeId.Luggage, AmenityTypeId.Outlets].includes(amenity.id))
  }

  mounted(): void {
    if (this.bookedByName === 'Sales Bot') {
      this.paidAmenitiesList = this.filteredAmenities
      .filter((amenity) => Object.values(paidAmenityTypeIds).includes(amenity.id))
    }

    this.guaranteedAmenitiesList = quoteFormAmenities
      .filter(amenity => Object.values(guaranteedAmenityTypeIds).includes(amenity.id))
      .filter(amenity => !this.paidAmenitiesList.some(a => a.id === amenity.id))

    this.bestEffortAmenitiesList = quoteFormAmenities
      .filter(amenity => Object.values(bestEffortAmenityTypeIds).includes(amenity.id))
  }

  isSelected(amenity): boolean {
    return this.filteredAmenities.some(a => a.id === amenity.id)
  }

  selectAmenity(amenity, selected): void {
    if (selected) {
      this.$emit('add-amenity', amenity)
    } else {
      this.$emit('remove-amenity', amenity)
    }
  }

  updateAmenityNoteHtml(amenity, value): void {
    this.$emit('set-amenity-note-html', amenity.id, value)
  }

  updateAmenityNoteText(amenity, value): void {
    this.$emit('set-amenity-note-text', amenity.id, value)
  }

}
