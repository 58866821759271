<template>
  <v-container
    id="quote-form"
    class="add-quote-container"
    style="margin-bottom: 500px"
    :class="sideBarToggled"
    fluid
  >
    <v-layout v-if="!isActive">
      <v-layout row align-center class="deleted-warning">
        <CRIcon class="deleted-warning-icon" material :height="36" :width="36">
          error_color
        </CRIcon>
        <p style="margin: 0px">This quote has been deleted.</p>
      </v-layout>
    </v-layout>
    <v-dialog v-model="showMarketRatesDialog" max-width="800" height="600">
      <template #activator="marketRatesActivator">
        <v-btn
          v-if="canViewMarketDetails"
          id="quote-form-market-rates-button"
          fab
          icon
          large
          class="market-rates-button"
          v-on="marketRatesActivator.on"
          @click="showMarketsRatesMap = true"
        >
          <CRIcon color="primary" :width="36" :height="36">money_pin</CRIcon>
        </v-btn>
      </template>

      <QuoteFormMarketRatesMap v-if="showMarketsRatesMap" />
    </v-dialog>

    <v-layout v-if="prequoteLeadDetected" class="sheet" column>
      <v-flex xs 12>
        <v-layout row>
          <h1 class="page-header">Pre-Quote Lead Detected</h1>
        </v-layout>
        <v-layout row wrap>
          <v-flex class="lead-overview-column">
            <strong>Created On:</strong>
            <span>{{ preQuoteLeadCreatedOnDisplayText }}</span>
            <br />
            <strong>Caller Name:</strong>
            <span>{{ `${lead.firstName} ${lead.lastName}` }}</span>
            <br />
            <strong>Caller Number:</strong>
            <span>{{ phoneFormatFilter(lead.phone) }}</span>
            <br />
            <strong>Organization:</strong>
            <span>{{ lead.organization }}</span>
          </v-flex>

          <v-flex class="lead-overview-column">
            <strong>How many passengers:</strong>
            <span>{{ lead.passengerCount }}</span>
            <br />
            <strong>Pickup Date:</strong>
            <span>{{ preQuoteLeadPickupDateDisplayText }}</span>
            <br />
            <strong>Dropoff Date:</strong>
            <span>{{ preQuoteLeadDropoffDateDisplayText }}</span>
            <br />
            <strong>Bookings Per Year:</strong>
            <span>{{ lead.bookingsPerYear }}</span>
            <br />
            <strong>Booking For:</strong>
            <span>{{ lead.bookingFor }}</span>
          </v-flex>

          <v-flex class="lead-overview-column">
            <strong>Notes:</strong>
            <span>{{ lead.notes }}</span>
          </v-flex>

          <v-spacer />

          <v-flex shrink>
            <v-btn color="primary" outline @click="populateFromLead">
              Select Lead for Quote
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout class="sheet top-section" column>
      <v-container style="margin: 0; padding: 0; max-width: none">
        <v-layout row>
          <v-flex column>
            <h1 class="page-header">
              <v-layout row justify-start align-center>
                {{ modeTitle }} Quote
                <span v-if="quote.quoteId">&nbsp;ID: {{ quote.quoteId }}</span>
                <TierBadge
                  v-if="showTierMarker"
                  class="margin-l-2"
                  :tier="quoteTier"
                />
              </v-layout>
              <span v-if="quote.createdOn" class="timestamp">
                <br />
                <span class="label">Created:</span>
                {{ createdOnDisplayText }}
              </span>
              <span v-if="quote.updatedOn" class="timestamp">
                -
                <span class="label">Last Updated:</span>
                {{ updatedOnDisplayText }}
              </span>
              <span
                v-if="quote && quote.createdBy && !isModeAdd"
                class="timestamp"
              >
                -
                <span class="label">Created By:</span>
                {{ quote.createdBy.firstName }} {{ quote.createdBy.lastName }}
              </span>
            </h1>
          </v-flex>

          <v-btn
            v-if="isModeView && !isConverted"
            color="primary"
            :disabled="!isActive"
            @click="editMode"
          >
            Edit Quote
          </v-btn>

          <v-menu v-if="isModeView">
            <template #activator="actionMenu">
              <v-btn
                id="quote-form-actions-menu"
                class="btn-secondaryaction"
                v-on="actionMenu.on"
              >
                Actions
                <v-icon>arrow_drop_down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <template v-if="showMarketplaceFeatures">
                <v-list-tile
                  :id="`quote-form-actions-menu-view-bids-${checkoutPageTypeString}`"
                  :style="{ cursor: 'pointer' }"
                  @click="
                    $router.push({ path: `/quotes/view/${quote.quoteId}/bids` })
                  "
                >
                  <v-list-tile-title>
                    {{
                      quote.pricingMethod === 'category'
                        ? 'View Checkout'
                        : 'View Bids'
                    }}
                  </v-list-tile-title>
                </v-list-tile>

                <v-list-tile
                  v-if="!quote.isConfirmed"
                  :id="`quote-form-actions-menu-view-confirmation-${checkoutPageTypeString}`"
                  :style="{ cursor: 'pointer' }"
                  @click="
                    $router.push({
                      path: `/quotes/view/${quote.quoteId}/confirmation`,
                    })
                  "
                >
                  <v-list-tile-title>View Confirmation</v-list-tile-title>
                </v-list-tile>

                <v-list-tile
                  :id="`quote-form-actions-menu-view-detail-${checkoutPageTypeString}`"
                  :style="{ cursor: 'pointer' }"
                  @click="
                    $router.push({ path: `/quotes/view/${quote.quoteId}` })
                  "
                >
                  <v-list-tile-title>View Detail</v-list-tile-title>
                </v-list-tile>

                <v-list-tile
                  :id="`quote-form-actions-menu-send-to-client-${checkoutPageTypeString}`"
                  :style="{ cursor: 'pointer' }"
                  @click="
                    saveQuoteHandler(
                      false,
                      marketStoreChangesForMarketplaceSendToClient,
                      false
                    )
                  "
                >
                  <v-list-tile-title>Send Quote to Client</v-list-tile-title>
                </v-list-tile>

                <v-list-tile
                  :id="`quote-form-actions-menu-send-pdf-invoice-${checkoutPageTypeString}`"
                  :style="{ cursor: 'pointer' }"
                  :disabled="isConverted"
                  @click="openPDFInvoicePanel"
                >
                  <v-list-tile-title>PDF Invoice Estimate</v-list-tile-title>
                </v-list-tile>

                <v-list-tile
                  :id="`quote-form-actions-menu-copy-marketplace-link-${checkoutPageTypeString}`"
                  :style="{ cursor: 'pointer' }"
                  @click="copyMarketplaceLink"
                >
                  <v-list-tile-title>Copy Marketplace Link</v-list-tile-title>
                </v-list-tile>
              </template>

              <v-list-tile
                v-if="!showMarketplaceFeatures"
                :id="`quote-form-actions-menu-view-checkout-${checkoutPageTypeString}`"
                :style="{ cursor: 'pointer' }"
                @click="viewCheckoutPage"
              >
                <v-list-tile-title>View Checkout</v-list-tile-title>
              </v-list-tile>

              <v-list-tile
                v-if="!isConverted && showApproveQuote"
                :id="`quote-form-actions-menu-approve-quote-${approveQuoteTypeString}`"
                :style="{ cursor: 'pointer' }"
                @click="showDialog('approveQuote')"
              >
                <v-list-tile-title>Approve Quote</v-list-tile-title>
              </v-list-tile>

              <v-list-tile
                id="quote-form-actions-menu-view-pdf"
                :style="{ cursor: 'pointer' }"
                @click="viewPDF"
              >
                <v-list-tile-title>View PDF</v-list-tile-title>
              </v-list-tile>

              <v-list-tile
                id="quote-form-actions-menu-copy-pdf-url"
                :style="{ cursor: 'pointer' }"
                @click="copyPDFUrl"
              >
                <v-list-tile-title>Copy PDF URL</v-list-tile-title>
              </v-list-tile>

              <v-list-tile
                id="quote-form-actions-menu-edit-temp-follow-up"
                :style="{ cursor: 'pointer' }"
                @click="updateLeadTempAndFollowUpDate"
              >
                <v-list-tile-title>CRM Actions</v-list-tile-title>
              </v-list-tile>

              <v-list-tile
                v-if="!quote.participatingInEmailCampaigns"
                id="quote-form-actions-menu-enable-emails"
                :style="{ cursor: 'pointer' }"
                @click="
                  (evt) =>
                    enableCampaign({
                      status: true,
                      quoteId: quote.quoteId,
                    }).then(() => {
                      quote.participatingInEmailCampaigns = true
                    })
                "
              >
                <v-list-tile-title>Enable Email Campaign</v-list-tile-title>
              </v-list-tile>

              <v-list-tile
                v-if="quote.participatingInEmailCampaigns"
                id="quote-form-actions-menu-disable-emails"
                :style="{ cursor: 'pointer' }"
                @click="
                  (evt) =>
                    enableCampaign({
                      status: false,
                      quoteId: quote.quoteId,
                    }).then(() => {
                      quote.participatingInEmailCampaigns = false
                    })
                "
              >
                <v-list-tile-title>Disable Email Campaign</v-list-tile-title>
              </v-list-tile>

              <v-list-tile
                v-if="isConverted"
                id="quote-form-actions-menu-link-to-contract"
                :style="{ cursor: 'pointer' }"
                @click="linkToContract"
              >
                <v-list-tile-title>Link To Contract</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                v-if="isConverted"
                id="quote-form-actions-menu-update-marketplace"
                :style="{ cursor: 'pointer' }"
                @click="updateMarketplace"
              >
                <v-list-tile-title>Update Marketplace</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                v-if="isBrokerAdmin || isRAManager"
                id="quote-form-actions-menu-update-created-by"
                :style="{ cursor: 'pointer' }"
                @click="updateSentBy"
              >
                <v-list-tile-title>Update Sent By User</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-layout>

        <v-layout
          v-if="!showingMarketplaceViewForClient && !loadingQuoteReset"
          row
        >
          <v-form
            ref="leadsource-checkoutpage-form"
            style="width: 100%; display: inline-flex"
          >
            <v-layout padded wrap>
              <v-flex xs12>
                <v-layout>
                  <v-flex md12 lg6>
                    <v-layout>
                      <v-flex xs12>
                        <div style="display: flex; align-items: center">
                          <p class="margin-a-0 padding-r-2 font-16">
                            Quote Configuration
                          </p>
                          <v-tooltip
                            v-if="
                              isBrokerAdmin ||
                              isRAManager ||
                              isSeniorISR ||
                              isMmeSales
                            "
                            right
                          >
                            <template #activator="{ on }">
                              <div v-on="on">
                                <CRIcon
                                  view-box="0 0 24 20"
                                  :width="24"
                                  :height="24"
                                  color="#9e9e9e"
                                  class="cursor-pointer"
                                >
                                  info
                                </CRIcon>
                              </div>
                            </template>
                            <span>
                              CharterUP brand can quote all three pricing
                              methods, non CharterUP brands can only price
                              single price
                            </span>
                          </v-tooltip>
                        </div>
                      </v-flex>
                    </v-layout>

                    <v-form ref="quote-form-configurations" class="w-full">
                      <v-layout>
                        <v-flex xs4 class="padding-r-2">
                          <CRInput
                            v-if="!isModeAdd"
                            id="quote-form-lead-source"
                            v-model="quote.leadSource.label"
                            label="Lead Source"
                            :disabled="true"
                          />
                          <div v-if="isModeAdd">
                            <div
                              v-if="
                                isBrokerAdmin ||
                                isRAManager ||
                                isSeniorISR ||
                                isMmeSales
                              "
                            >
                              <CRInput
                                id="quote-form-lead-source"
                                v-model="quote.leadSource.label"
                                label="Lead Source"
                                type="autocomplete"
                                :items="suggestedLeadSources"
                                item-text="displayName"
                                item-key="leadSourceId"
                                :return-object="true"
                                :search-input.sync="leadSourceSearchTerm"
                                :clearable="true"
                                clear-icon="replay"
                                placeholder="Enter lead source name"
                                browser-autocomplete="off"
                                :disabled="
                                  disableContractDetailFields ||
                                  quote.isPreBooking
                                "
                                @input="selectLeadSourceFromList"
                                @click:clear="resetLeadSource"
                                @change="clearPricingTable = true"
                              />
                              <span
                                v-if="
                                  op(quote, 'leadSource/id') === null &&
                                  showLeadSourceError
                                "
                                class="error-message"
                              >
                                Lead Source Required
                              </span>
                            </div>
                            <div v-else>
                              <CRInput
                                v-if="quote.leadSource"
                                v-model="quote.leadSource.label"
                                label="Lead Source"
                                default="Bus Company"
                                disabled
                                hide-details
                              />
                              <div
                                style="
                                  display: flex;
                                  flex-direction: column;
                                  min-height: 21px;
                                "
                              >
                                <p
                                  v-show="showLeadSourceButton"
                                  class="lead-source-button"
                                  @click="setLeadSource"
                                >
                                  Set to CharterUP
                                </p>
                              </div>
                            </div>
                          </div>
                        </v-flex>
                        <v-flex
                          v-if="
                            isBrokerAdmin ||
                            isRAManager ||
                            isSeniorISR ||
                            isMmeSales
                          "
                          xs4
                          class="padding-r-2"
                        >
                          <CRInput
                            v-if="!isModeAdd"
                            id="quote-form-checkout-page"
                            v-model="quote.checkoutPage.label"
                            label="Brand"
                            :disabled="true"
                          />
                          <CRInput
                            v-if="isModeAdd"
                            id="quote-form-checkout-page"
                            :key="`quote-form-checkout-page-${quote.checkoutPage.label}`"
                            v-model="quote.checkoutPage.label"
                            required
                            type="autocomplete"
                            :items="suggestedCheckoutPages"
                            item-text="name"
                            item-key="companyCheckoutPageId"
                            :return-object="true"
                            label="Brand"
                            :clearable="true"
                            clear-icon="replay"
                            :disabled="
                              disableContractDetailFields || quote.isPreBooking
                            "
                            :search-input.sync="checkoutPageSearchTerm"
                            placeholder="Brand"
                            browser-autocomplete="off"
                            @click:clear="resetCheckoutPage"
                            @input="selectCheckoutPageFromList"
                          />
                          <span
                            v-if="
                              op(quote, 'checkoutPage/id') === null &&
                              showCheckoutPageError
                            "
                            class="error-message"
                          >
                            Brand Required
                          </span>
                        </v-flex>
                        <v-flex
                          v-if="
                            isBrokerAdmin ||
                            isRAManager ||
                            isSeniorISR ||
                            isMmeSales
                          "
                          xs4
                        >
                          <CRInput
                            v-if="!isModeAdd"
                            id="quote-form-pricing-method"
                            :value="pricingMethodLabel"
                            label="Quote Pricing"
                            :disabled="true"
                          />
                          <CRSelect
                            v-if="isModeAdd"
                            id="quote-form-pricing-method"
                            v-model="quote.pricingMethod"
                            :items="availablePricingMethods"
                            :disabled="!quote.checkoutPage.id"
                            label="Quote Pricing"
                            item-text="label"
                            item-value="key"
                            :loading="loading"
                            :rules="[
                              (val) =>
                                isNotEmpty(val) || 'Quote Pricing Is Required',
                            ]"
                          />
                        </v-flex>
                      </v-layout>
                    </v-form>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex
                v-if="isBrokerAdmin || isSeniorISR || isMmeSales || isRAManager"
                xs12
              >
                <v-layout>
                  <v-flex v-bind="otherConfigsFlexProps">
                    <v-layout>
                      <v-flex xs12>
                        <div style="display: flex; align-items: center">
                          <p class="margin-a-0 padding-r-2 font-16">
                            Other Configurations
                          </p>
                          <v-tooltip right>
                            <template #activator="{ on }">
                              <div v-on="on">
                                <CRIcon
                                  view-box="0 0 24 20"
                                  :width="24"
                                  :height="24"
                                  color="#9e9e9e"
                                  class="cursor-pointer"
                                >
                                  info
                                </CRIcon>
                              </div>
                            </template>
                            <span>
                              Contracts can only be added if they are already
                              created
                            </span>
                          </v-tooltip>
                        </div>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex xs4 class="padding-r-2">
                        <CRSelect
                          id="quote-form-currency-type"
                          v-model="quote.currencyType"
                          label="Currency"
                          :items="['USD', 'CAD']"
                          :disabled="isModeView"
                          @input="changeCurrencyType"
                        />
                      </v-flex>
                      <v-flex xs4 class="padding-r-2">
                        <CRInput
                          id="quote-form-contract-name"
                          :value="contract"
                          label="Contract (Optional)"
                          type="autocomplete"
                          :items="suggestedContracts"
                          item-text="contractName"
                          item-key="quoteId"
                          :disabled="isModeView || disableContractDetailFields"
                          :return-object="true"
                          :search-input.sync="contractSearchTerm"
                          :clearable="true"
                          clear-icon="close"
                          placeholder="Enter Contract Name"
                          browser-autocomplete="off"
                          @input="selectContractFromList"
                        ></CRInput>
                      </v-flex>
                      <v-flex v-if="canEditTiers" xs4>
                        <CRSelect
                          id="quote-form-tier"
                          v-model="quote.tierId"
                          label="Service Tier"
                          :items="tiers"
                          :disabled="isModeView"
                          item-text="label"
                          item-value="tierId"
                          @input="(value) => setQuoteTier(value, false)"
                        />
                      </v-flex>
                      <v-flex
                        v-if="canCreatePreBooking"
                        xs4
                        class="padding-l-2"
                      >
                        <v-checkbox
                          id="quote-form-pre-booking-checkbox"
                          v-model="quote.isPreBooking"
                          class="pre-booking-checkbox margin-t-5"
                          label="Pre-Booking Quote"
                          hide-details
                          :disabled="quote.charterUpQuote"
                          @change="handlePreBookingToggle"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
        </v-layout>
        <v-layout v-else-if="loadingQuoteReset" row align-center justify-center>
          <!-- Using primary keyword doesn't seem to set color here -->
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <v-container>
        <component
          :is="componentForClientQuoteView"
          v-if="showingMarketplaceViewForClient && !!quote && quote.quoteId"
          :quote="quote"
          :is-quote-expired="isQuoteExpired"
        />
      </v-container>
    </v-layout>

    <div v-show="!showingMarketplaceViewForClient && !loadingQuoteReset">
      <v-flex row class="sheet pa-0">
        <UserSelector
          ref="customer-form"
          v-model="quote.customer"
          :quote-id="quote?.quoteId"
          :mode="mode"
          :is-customer-selected="isCustomerSelected"
          :past-quotes-button="quote.customer.customerId && isCustomerSelected"
          :show-past-quotes="showPastQuotes"
          :show-last-quote="showLastQuote"
          is-new-display
          :industry-required="true"
          :populated-from-lead="populatedFromLead"
          :is-editing-customer="isEditingCustomer"
          @new-customer-account="quote.customer.customerAccount = $event"
          @toggle-show-past-quotes="showPastQuotes = !showPastQuotes"
          @toggle-show-last-quote="toggleShowLastQuote"
          @set-is-customer-selected="
            (value) => {
              isCustomerSelected = value
            }
          "
          @set-customer-account="
            (value) => {
              customerAccount = value
            }
          "
          @set-is-editing-customer="
            (value) => {
              isEditingCustomer = value
            }
          "
          @user-details-found="populateCustomerDetails"
        />

        <div v-if="shouldDisplayPastQuotesTable" class="results">
          <QuotePastQuotesTable
            :key="`customer-quote-table-${quote.customer.id}`"
            :customer="quote.customer"
            :last-quote-id="lastQuoteId"
            :show-last-quote="showLastQuote"
            @duplicate-trips="duplicateTrips"
          />
        </div>
      </v-flex>

      <section class="sheet">
        <v-layout align-center fill-height class="tabs">
          <v-tabs v-model="selectedTrip">
            <v-tab
              v-for="(trip, tripIndex) in quote.trips"
              :key="`trip-tab-${tripIndex}`"
              :href="`#trip-${tripIndex}`"
              :class="
                (isModeAdd || isModeEdit) &&
                formSubmitted &&
                ((validationResults &&
                  validationResults[tripIndex] &&
                  validationResults[tripIndex].hasFailures === true) ||
                  (validationResults[`${tripIndex}-payment`] &&
                    validationResults[`${tripIndex}-payment`].hasFailures ===
                      true) ||
                  (validationResults[`${tripIndex}-payment-method`] &&
                    validationResults[`${tripIndex}-payment-method`]
                      .hasFailures === true) ||
                  (validationResults[`${tripIndex}-detail`] &&
                    validationResults[`${tripIndex}-detail`].hasFailures ===
                      true) ||
                  (validationResults[`${tripIndex}-market`] &&
                    validationResults[`${tripIndex}-market`].hasFailures ===
                      true) ||
                  shouldSplitTrip)
                  ? 'quote-trip-tab failed-validation'
                  : 'quote-trip-tab'
              "
              @click="changeActiveTripIndex(tripIndex)"
            >
              <v-tooltip top size="14" class="raised-tool-tip">
                <template #activator="{ on }">
                  <v-icon v-if="shouldSplitTrip" class="active-error" v-on="on">
                    error_outline
                  </v-icon>
                </template>
                <div>
                  To price this trip correctly,
                  <br />
                  it should be separated into multiple one-way trips.
                  <br />
                  Click Convert to Multi-Trip to update the quote.
                </div>
              </v-tooltip>
              <QuoteFormTripNameEditor
                :trip="trip"
                :index="tripIndex"
                :active="activeTripIndex === tripIndex"
                :mode="mode"
                @name-saved="changeTripName(trip, $event)"
                @clear="clearTripName(trip)"
              />
              <v-btn
                v-if="!isModeView && quote.trips.length > 1"
                :id="`quote-form-remove-trip-button-${tripIndex}`"
                v-model="selectedTrip"
                icon
                class="icon-button-close"
                @click.stop="removeTrip(tripIndex)"
              >
                <v-icon class="tab-icon-close">close</v-icon>
              </v-btn>
              <a
                v-if="!isModeView && shouldSplitTrip"
                @click="confirmSplitTripDialog = !confirmSplitTripDialog"
              >
                Convert to Multi-Trip
              </a>
            </v-tab>

            <v-tab href="#summary" style="font-weight: 700">Summary</v-tab>
          </v-tabs>
          <v-flex>
            <v-menu v-if="!isModeView">
              <template #activator="tripActionMenu">
                <a id="quote-form-add-button" v-on="tripActionMenu.on">+ Add</a>
              </template>
              <v-list>
                <v-list-tile
                  id="quote-form-add-new-trip-button"
                  :style="{ cursor: 'pointer' }"
                  @click="addTrip"
                >
                  <v-list-tile-title>Add New Trip</v-list-tile-title>
                </v-list-tile>
                <template v-for="(trip, tripIndex) in quote.trips">
                  <v-list-tile
                    :id="`quote-form-duplicate-trip-from-previous-button-trip-${tripIndex}`"
                    :key="`trip_action_${tripIndex}`"
                    :style="{ cursor: 'pointer' }"
                    @click="duplicateTripFromPrevious(trip, tripIndex)"
                  >
                    <v-list-tile-title>
                      Copy
                      {{
                        trip.routeName
                          ? trip.routeName
                          : `Trip ${tripIndex + 1}`
                      }}
                    </v-list-tile-title>
                  </v-list-tile>
                </template>
              </v-list>
            </v-menu>
          </v-flex>
        </v-layout>

        <!-- Maybe move this to the TripDetail component -->
        <v-layout row wrap>
          <v-flex xs4>
            <v-btn-toggle
              v-if="selectedTrip !== 'summary'"
              ref="trip-details-toggle"
              v-model="toggleSubTabs"
              mandatory
              class="elevation-0 mt-4 trip-details-button"
            >
              <v-btn
                :id="`quote-form-trip-details-button-${selectedTripIndex}`"
                :class="subTabClass(selectedTripIndex, 'detail', 0)"
                flat
                @click="toggleSubTabs = 0"
              >
                Trip Details
              </v-btn>

              <v-btn
                :id="`quote-form-trip-payment-button-${selectedTripIndex}`"
                :class="subTabClass(selectedTripIndex, 'payment', 1)"
                flat
                style="border-radius: 0"
                @click="toggleSubTabs = 1"
              >
                Payment
              </v-btn>

              <v-btn
                :id="`quote-form-trip-notes-button-${selectedTripIndex}`"
                :class="subTabClass(selectedTripIndex, 'notes', 2)"
                flat
                style="border-radius: 0"
                @click="toggleSubTabs = 2"
              >
                Notes
              </v-btn>

              <v-btn
                v-if="isNewTripAmenitiesEnabled"
                :id="`quote-form-trip-amenities-button-${selectedTripIndex}`"
                :class="subTabClass(selectedTripIndex, 'amenities', 5)"
                flat
                style="border-radius: 0"
                @click="toggleSubTabs = 5"
              >
                Amenities
              </v-btn>

              <v-btn
                :id="`quote-form-trip-recurrence-button-${selectedTripIndex}`"
                :class="subTabClass(selectedTripIndex, 'recurrence', 3)"
                flat
                style="border-radius: 0"
                @click="toggleSubTabs = 3"
              >
                Recurrence
              </v-btn>

              <v-btn
                :id="`quote-form-trip-contact-button-${selectedTripIndex}`"
                :class="subTabClass(selectedTripIndex, 'contact', 4)"
                flat
                @click="toggleSubTabs = 4"
              >
                Trip Contact
              </v-btn>
            </v-btn-toggle>
          </v-flex>
        </v-layout>

        <div v-show="toggleSubTabs === 0" class="tab-content">
          <template v-for="(trip, tripIndex) in trips">
            <QuoteFormTripDetail
              v-show="selectedTrip === `trip-${tripIndex}`"
              :id="`quote-form-trip-detail-${tripIndex}`"
              ref="quote-form-trip-detail"
              :key="`quote-form-trip-detail-${tripIndex}`"
              v-model="quote.trips[tripIndex]"
              :validation-key="validationKey"
              :distance-update-key="distanceUpdateKey"
              :trip-index="tripIndex"
              :trip-types="tripTypes"
              :vehicle-types="vehicleTypes"
              :suggested-pricing="quote.trips[tripIndex].suggestedPricing"
              :live-mile-label="quote.trips[tripIndex].liveMileLabel"
              :dead-mile-label="quote.trips[tripIndex].deadMileLabel"
              :pricing-market="quote.trips[tripIndex].pricingMarket"
              :estimated-time-label="quote.trips[tripIndex].estimatedTimeLabel"
              :pricing-time-label="quote.pricingTimeLabel"
              :calculating-estimations="calculatingEstimations"
              :toggled="toggleSubTabs === 0"
              :mode="mode"
              :quote="quote"
              :pricing-data="pricingData"
              :vehicle-conflict="hasVehicleConflict(tripIndex)"
              :is-new-trip-amenities-enabled="isNewTripAmenitiesEnabled"
              :booked-by-name="quote.createdByName"
              @get-pricing="getPricing"
              @validation-results="captureValidationResults"
              @set-due-date="setDueDateKey"
              @set-vehicle-needed-for-trip="setVehicleNeededForEntireTrip"
              @vehicle-changed="clearTripVehicleConflict($event)"
              @pickup-date-time-changed="autoSetExpirationDateTime"
            />
          </template>
        </div>

        <div v-show="toggleSubTabs === 1" class="tab-content">
          <template v-for="(trip, tripIndex) in trips">
            <QuoteFormTripPaymentV2
              v-if="isCapturePaymentStagesEnabled"
              v-show="selectedTrip === `trip-${tripIndex}`"
              :id="`quote-form-trip-payment-${tripIndex}`"
              :key="`quote-form-trip-payment-v2-${tripIndex}`"
              :trip-data="quote.trips[tripIndex]"
              :can-open-charter-up-quote="canOpenCharterUpQuote"
              :validation-key="validationKey"
              :due-date-key="dueDateKey"
              :trip-index="tripIndex"
              :payment-types="paymentTypes"
              :quote="quote"
              :charge-types="chargeTypes"
              :rate-types="rateTypes"
              :exchange-rate="exchangeRate"
              :currency-type="quote.currencyType"
              :payment-method-types="paymentMethodTypes"
              :live-mile-label="quote.trips[tripIndex].liveMileLabel"
              :dead-mile-label="quote.trips[tripIndex].deadMileLabel"
              :estimated-time-label="quote.trips[tripIndex].estimatedTimeLabel"
              :calculating-estimations="calculatingEstimations"
              :mode="mode"
              :clear-pricing-table="clearPricingTable"
              :refresh-pricing="trip.refreshPricing"
              :show-marketplace-features="showMarketplaceFeatures"
              :customer-account-defaults="customerAccountDefaults"
              @pricing-data="pricingData = $event"
              @get-pricing="getPricing"
              @validation-results="captureValidationResults"
              @pricing-table-cleared="clearPricingTable = false"
              @refresh-pricing="trip.refreshPricing = false"
            />
            <QuoteFormTripPayment
              v-else
              v-show="selectedTrip === `trip-${tripIndex}`"
              :id="`quote-form-trip-payment-${tripIndex}`"
              :key="`quote-form-trip-payment-${tripIndex}`"
              v-model="quote.trips[tripIndex]"
              :can-open-charter-up-quote="canOpenCharterUpQuote"
              :validation-key="validationKey"
              :due-date-key="dueDateKey"
              :trip-index="tripIndex"
              :payment-types="paymentTypes"
              :quote="quote"
              :charge-types="chargeTypes"
              :rate-types="rateTypes"
              :exchange-rate="exchangeRate"
              :currency-type="quote.currencyType"
              :payment-method-types="paymentMethodTypes"
              :suggested-pricing="quote.trips[tripIndex].suggestedPricing"
              :live-mile-label="quote.trips[tripIndex].liveMileLabel"
              :dead-mile-label="quote.trips[tripIndex].deadMileLabel"
              :estimated-time-label="quote.trips[tripIndex].estimatedTimeLabel"
              :calculating-estimations="calculatingEstimations"
              :toggled="toggleSubTabs === 1"
              :mode="mode"
              :clear-pricing-table="clearPricingTable"
              :pricing-data="pricingData"
              :refresh-pricing="trip.refreshPricing"
              :show-marketplace-features="showMarketplaceFeatures"
              :customer-account-defaults="customerAccountDefaults"
              @pricing-data="pricingData = $event"
              @get-pricing="getPricing"
              @validation-results="captureValidationResults"
              @pricing-table-cleared="clearPricingTable = false"
              @refresh-pricing="trip.refreshPricing = false"
              @update-trip-processing-percentage="
                updateTripProcessingPercentage(tripIndex, $event)
              "
            />
          </template>
        </div>

        <v-container v-show="toggleSubTabs === 2" class="tab-content">
          Trip Notes
          <CRRichText
            v-if="quote.trips[selectedTripIndex]"
            :id="`quote-form-customer-notes-description-trip-${selectedTripIndex}`"
            :note="previousTripNotes.customerNotes"
            multi-line
            :read-only="isModeView"
            :disabled="isModeView"
            placeholder="Trip notes for you, the customer, and the operator."
            class="mb-4"
            @htmlchange="updateTripNoteHtml"
            @textchange="updateTripNoteText"
          />
          Office Notes
          <CRRichText
            v-if="quote.trips[selectedTripIndex]"
            :id="`quote-form-customer-notes-text-trip-${selectedTripIndex}`"
            :note="previousTripNotes.officeNotes"
            multi-line
            :read-only="isModeView"
            :disabled="isModeView"
            placeholder="Trip notes for internal use only (customers and operators will not see this)"
            class="mb-4"
            @htmlchange="updateOfficeNoteHtml"
            @textchange="updateOfficeNoteText"
          />
          <div v-if="isSuperAdmin">
            Driver Notes
            <CRRichText
              v-if="quote.trips[selectedTripIndex]"
              :id="`quote-form-driver-notes-text-trip-${selectedTripIndex}`"
              :note="previousTripNotes.driverNotes"
              multi-line
              :read-only="isModeView"
              :disabled="isModeView"
              :placeholder="`Trip notes for you and the driver (customers will not see this).`"
              class="mb-4"
              @htmlchange="updateDriverNoteHtml"
              @textchange="updateDriverNoteText"
            />
          </div>
        </v-container>

        <v-container v-if="isNewTripAmenitiesEnabled" v-show="toggleSubTabs === 5" class="tab-content margin-a-0">
          <template v-if="quote.trips[selectedTripIndex] && !loadingQuoteReset">
            <QuoteFormAmenities
              :trip-amenities="quote.trips[selectedTripIndex].tripAmenities"
              :trip-index="selectedTripIndex"
              :disabled="isModeView"
              :booked-by-name="quote.createdByName"
              @add-amenity="addAmenity"
              @remove-amenity="removeAmenity"
              @set-amenity-note-html="setAmenityNoteHtml"
              @set-amenity-note-text="setAmenityNoteText"
            />
          </template>
        </v-container>

        <v-container v-show="toggleSubTabs === 3" class="tab-content">
          <template v-if="quote.trips[selectedTripIndex]">
            <RecurrencesInput
              v-model="quote.trips[selectedTripIndex].recurrences"
              :original-recurrence-event="recurrenceEventObjectForSelectedTrip"
              :time-zone="getTimeZoneForTrip(quote.trips[selectedTripIndex])"
              :disabled="isModeView"
              @validation-results="captureValidationResults"
            />

            <!-- TODO: Create a model prop for this -->
            <RecurrencesCalendar
              v-model="quote.trips[selectedTripIndex].recurrences"
              :original-recurrence-event="recurrenceEventObjectForSelectedTrip"
              :time-zone="getTimeZoneForTrip(quote.trips[selectedTripIndex])"
            />
          </template>
        </v-container>

        <v-container v-show="toggleSubTabs === 4" class="tab-content">
          <v-checkbox
            v-if="!isModeView && quote.trips[selectedTripIndex]"
            :id="`quote-form-checkbox-customer-notes-is-customer-${selectedTripIndex}`"
            v-model="quote.trips[selectedTripIndex].tripContactIsCustomer"
            background-color="white"
            solo
            :disabled="!quote.customer.customerId"
            color="primary"
            label="Trip Contact is Customer"
          />
          <UserSelector
            v-if="
              trips[selectedTripIndex] &&
              !trips[selectedTripIndex].tripContactIsCustomer
            "
            :id="`quote-form-user-selector-is-selected-${selectedTripIndex}`"
            v-model="quote.trips[selectedTripIndex].tripContact"
            :quote-id="quote?.quoteId"
            :user-title="'Trip Contact'"
            :mode="mode"
            :is-customer-selected="
              quote.trips[selectedTripIndex].istripContactSelected
            "
            :populated-from-lead="populatedFromLead"
            @set-is-customer-selected="
              (value) => {
                quote.trips[selectedTripIndex].istripContactSelected = value
              }
            "
          />
          <UserSelector
            v-else
            v-model="quote.customer"
            :quote-id="quote?.quoteId"
            :user-title="'Trip Contact'"
            mode="view"
            :is-customer-selected="true"
            :populated-from-lead="populatedFromLead"
          />
        </v-container>
        <div v-if="selectedTrip === 'summary'" id="quote-form-summary">
          <QuoteFormSummary :trips="trips" :vehicle-types="vehicleTypes" />
        </div>
      </section>
      <section>
        <v-layout row class="lead-temp-follow-up">
          <QuoteFormLeadFollowUpDetail
            ref="quote-form-lead-follow-up"
            style="width: 50%"
            :quote="quote"
            :mode="mode"
            @set-lead-temperature="setLeadTemperatureType"
            @set-lead-follow-up-date="setLeadFollowUpDate"
            @set-quote-note-text="setQuoteNoteText"
            @set-quote-note-html="setQuoteNoteHtml"
          />
          <v-divider vertical class="margin-x-5 margin-b-0" />
          <v-layout column style="display: inline; width: 50%">
            <v-checkbox
              v-if="!isModeView && !quote.contractId"
              v-model="quote.soldOut"
              hide-details
              color="primary"
              label="Sold Out"
            />
            <v-checkbox
              v-if="!isModeView && !quote.contractId"
              v-model="quote.isPendingConfirmation"
              hide-details
              color="primary"
              label="Pending Confirmation"
            />
            <v-checkbox
              v-if="!isModeView || quote.isEnterprise"
              v-model="quote.isEnterprise"
              hide-details
              color="primary"
              :disabled="isModeView"
              label="Enterprise"
            />
            <v-form ref="quote-form-enterprise-fields">
              <v-layout
                v-if="isAccountExecutiveAndOpportunityIdEnabled"
                row
                class="margin-t-3"
              >
                <v-flex xs12>
                  <v-layout row>
                    <v-flex class="margin-r-2" xs6>
                      <AutoCompleteUser
                        label="Account Executive"
                        class="account-executive-autocomplete"
                        are-account-executives
                        :initial-user="quote.accountExecutiveId"
                        :disabled="isModeView"
                        hide-details
                        @user-selected="accountExecutiveSelected"
                      />
                      <span
                        v-if="showAccountExecutiveError"
                        class="error-message"
                      >
                        Account Executive Is Required
                      </span>
                    </v-flex>
                    <v-flex xs6>
                      <CRInput
                        v-model="quote.opportunityId"
                        label="Opportunity ID"
                        :disabled="isModeView"
                        :rules="
                          quote.isEnterprise
                            ? [
                                isRequired(true, isNotEmpty, {
                                  req: 'Quote Opportunity ID Is Required',
                                  error: 'Quote Opportunity ID Is Required',
                                }),
                                (val) =>
                                  val.length === 18 ||
                                  'Quote Opportunity ID Must Be 18 Characters',
                              ]
                            : [
                                (val) =>
                                  !val ||
                                  val.length === 18 ||
                                  'Quote Opportunity ID Must Be 18 Characters',
                              ]
                        "
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-form>
            <v-layout row>
              <v-flex xs6>
                <TeamSelection
                  ref="quote-form-classifications"
                  :mode="mode"
                  :existing-product-classification-id="
                    quote.productClassificationId
                  "
                  :existing-sourcing-team-classification-id="
                    quote.sourcingTeamClassificationId
                  "
                  :existing-support-team-classification-id="
                    quote.supportTeamClassificationId
                  "
                  :disabled="isModeView"
                  @product-input="changeClassification('product', $event)"
                  @sourcing-team-input="
                    changeClassification('sourcing-team', $event)
                  "
                  @support-team-input="
                    changeClassification('support-team', $event)
                  "
                />
              </v-flex>
            </v-layout>
          </v-layout>
        </v-layout>
      </section>
      <section>
        <div class="quote-totals">
          <QuoteFormFooterV2
            v-if="isCapturePaymentStagesEnabled"
            ref="quote-form-footer"
            :quote="quote"
            :total="total"
            :recurring-total="recurringTotal"
            :exchange-rate="exchangeRate"
            :required-deposit="requiredDeposit"
            :mode="mode"
            :lock-save-button="lockSaveButton"
            :save-quote-handler="saveQuoteHandler"
            :set-date-times="setDateTimes"
            :show-marketplace-features="showMarketplaceFeatures"
            :payment-types="paymentTypes"
            :is-editing-customer="isEditingCustomer"
            @set-is-expiration-autoset="
              quote.isExpirationDateTimeAutoset = $event
            "
          />
          <QuoteFormFooter
            v-else
            ref="quote-form-footer"
            :quote="quote"
            :total="total"
            :recurring-total="recurringTotal"
            :exchange-rate="exchangeRate"
            :required-deposit="requiredDeposit"
            :mode="mode"
            :lock-save-button="lockSaveButton"
            :save-quote-handler="saveQuoteHandler"
            :set-date-times="setDateTimes"
            :show-marketplace-features="showMarketplaceFeatures"
            :payment-types="paymentTypes"
            :is-editing-customer="isEditingCustomer"
            @set-is-expiration-autoset="
              quote.isExpirationDateTimeAutoset = $event
            "
          />
        </div>
        <v-dialog
          v-model="confirmSplitTripDialog"
          :max-width="dialogType === 'approveBASQuote' ? '360px' : '600px'"
        >
          <Confirm
            :id="`quote-form-split-trips`"
            header="Convert to Multi-trip"
            message="Are you sure you would like to convert this single trip to multiple trips?  You will not be able to undo this action. Please be sure the client does not require the bus for the entire trip."
            confirm-button-text="Convert"
            :is-dialog="true"
            :loading="confirmSplitTripLoading"
            @confirm="initializeSplitTrip"
            @cancel="confirmSplitTripDialog = false"
          />
        </v-dialog>
        <v-dialog
          v-model="actionsDialog"
          :max-width="dialogType === 'approveBASQuote' ? '360px' : '600px'"
        >
          <Confirm
            v-if="dialogType === 'approveBASQuote'"
            :id="`quote-form`"
            header="Approve Quote"
            message="Are you sure you want to approve this quote?"
            confirm-button-text="Approve"
            :is-dialog="true"
            @confirm="approveBASQuote"
            @cancel="onCloseDialog"
          />
        </v-dialog>
      </section>
      <PaymentSidebarWrapper
        :id="`quote-form-collect-payment-new`"
        v-model="dialogType"
        :quote-id="quote.quoteId"
        :quote-hash="quote.hash"
        :quote-total="total"
        quote-message="Quote successfully approved"
        v-bind="$attrs"
        @close-modal="onCloseDialog"
        @quote-approved="quoteApproved"
      />
    </div>

    <v-snackbar v-model="saveQuoteError" color="error">
      {{ saveQuoteErrorMessage }}
    </v-snackbar>

    <v-dialog
      v-model="unsavedChangesWarningDialog"
      :max-width="dialogType === 'approveBASQuote' ? '360px' : '600px'"
    >
      <Confirm
        :id="`quote-form-unsaved-changes`"
        header="Unsaved Changes"
        message="Are you sure you want to leave this page? Any unsaved changes will be lost."
        confirm-button-text="Leave Page"
        cancel-button-text="Stay on Page"
        :is-dialog="true"
        @confirm="confirmLeave"
        @cancel="confirmStay"
      />
    </v-dialog>

    <v-dialog
      v-model="newQuoteStopDatesInPastDialog"
      max-width="600px"
    >
      <Confirm
        :id="`new-quote-dates-in-past`"
        header="Trip Dates in Past"
        message="Looks like one or more pickup or dropoff dates are in the past. Are you sure you want to continue?"
        confirm-button-text="Continue & Create Quote"
        cancel-button-text="Cancel"
        :is-dialog="true"
        @confirm="confirmQuoteCreate"
        @cancel="confirmCancel"
      />
    </v-dialog>
  </v-container>
</template>

<script>
const INTERNAL_NOTE_TYPE = 1
const CUSTOMER_NOTE_TYPE = 2
const DRIVER_NOTE_TYPE = 3
const CHARTERUP_CHECKOUT_PAGE = {
  id: 4,
  companyCheckoutPageId: 4,
  checkoutPageId: 4,
  key: 'charterup',
  name: 'CharterUP',
  label: 'CharterUP',
  internalName: 'charterup',
}

const PRICING_METHODS_MAP = [
  {
    key: 'single_price',
    label: 'Single Price',
  },
  {
    key: 'bids',
    label: 'Bids',
  },
  {
    key: 'category',
    label: 'Category',
  },
]

import op from 'simple-object-path'
import { DateTime } from 'luxon'
import {
  ChargeTypeId,
  SplitFeatureFlag,
  PaymentTypeKey,
  PaymentMethodTypeKey,
  PaymentStageKey,
} from '@/utils/enum'

import { v4 } from 'uuid'
import { mapActions, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import { authComputed, callCenterComputed } from '@/state/helpers'
import { baseUrl } from '@/utils/env'
import contracts from '@/services/contracts'
import checkoutPages from '@/services/checkoutPages'
import customers from '@/services/customers'
import exchangeRate from '@/services/exchangeRate'
import leads from '@/services/leads'
import leadSources from '@/services/leadSources'
import recurrences from '@/services/recurrences'

import { deepClone } from '@/utils/deepClone'
import { filter } from '@/utils/filter'
import { phoneFormatFilter } from '@/utils/phone'
import * as logger from '@/utils/logger'
import { scrubQuoteForAPI } from '@/utils/quoteUtils'
import { environmentPrefix } from '@/utils/env'

import { getDatetimeFromDateAndTimeStrings } from '@/components/quoteFormhelpers'
import { findPermissionByName } from '@/utils/permissions'

import AutoCompleteUser from '@/components/AutoCompleteUser.vue'
import QuoteFormTripNameEditor from '@/components/QuoteFormTripNameEditor.vue'
import Confirm from '@/components/Confirm.vue'
import QuoteFormTripDetail from '@/components/QuoteFormTripDetail.vue'
import QuoteFormTripPayment from '@/components/QuoteFormTripPayment.vue'
import QuoteFormTripPaymentV2 from '@/components/QuoteFormTripPaymentV2.vue'
import RecurrencesInput from '@/components/RecurrencesInput.vue'
import RecurrencesCalendar from '@/components/RecurrencesCalendar.vue'
import QuoteFormSummary from '@/components/QuoteFormSummary.vue'
import QuotePastQuotesTable from '@/components/QuotePastQuotesTable.vue'
import QuoteFormMarketRatesMap from '@/components/QuoteFormMarketRatesMap.vue'
import QuoteFormFooter from '@/components/QuoteFormFooter.vue'
import QuoteFormFooterV2 from '@/components/QuoteFormFooterV2.vue'
import QuoteFormLeadFollowUpDetail from '@/components/QuoteFormLeadFollowUpDetail.vue'
import CustomizeEmailInvoice from '@/components/CustomizeEmailInvoice.vue'
import UserSelector from '@/components/UserSelector.vue'
import PaymentSidebarWrapper from '@/components/PaymentSidebarWrapper.vue'
import QuoteDetail from '@/views/charterup/QuoteDetail.vue'
import QuoteConfirmation from '@/views/charterup/QuoteConfirmation.vue'
import CRTag from '@/cr/components/CRTag.vue'
import TeamSelection from '@/components/TeamSelection.vue'
import TierBadge from '@/components/TierBadge.vue'
import { EventBus } from '@/utils/event-bus'
import { isRequired, isNotEmpty } from '@/utils/validators'
import QuoteFormAmenities from '@/components/QuoteFormAmenities.vue'

export default {
  metaInfo() {
    return {
      title: 'Quotes',
    }
  },
  components: {
    TeamSelection,
    AutoCompleteUser,
    QuoteFormLeadFollowUpDetail,
    QuoteFormTripDetail,
    QuoteFormTripPayment,
    QuoteFormTripPaymentV2,
    QuoteFormAmenities,
    RecurrencesInput,
    RecurrencesCalendar,
    CustomizeEmailInvoice,
    QuoteFormSummary,
    QuoteFormFooter,
    QuoteFormFooterV2,
    UserSelector,
    QuotePastQuotesTable,
    QuoteFormMarketRatesMap,
    Confirm,
    QuoteFormTripNameEditor,
    PaymentSidebarWrapper,
    CRTag,
    TierBadge,
  },
  directives: { mask },
  beforeRouteLeave(to, from, next) {
    if (to.name && to.name === 'login') {
      next()
    }
    if (!this.isModeView && this.quoteChanged && !this.lockSaveButton) {
      this.unsavedChangesLeaveAction = next
      this.handleUnsavedChanges()
    } else {
      next()
    }
  },
  props: {
    mode: { type: String, default: null },
    clientQuoteView: { type: String, default: null },
  },
  data() {
    return {
      op,
      showAccountExecutiveError: false,
      showExpirationDateError: false,
      confirmSplitTripDialog: false,
      confirmSplitTripLoading: false,
      isConverted: false,
      isBASQuote: false,
      showApproveQuote: false,
      populatedFromLead: false,
      dialogType: undefined,
      actionsDialog: false,
      exchangeRate: 1,
      showAlternativeCurrency: false,
      lockSaveButton: false,
      validationResults: {},
      onlyOption: false,
      customerAccount: null,
      alreadyInvitedToCharterUp: false,
      calculatingEstimations: false,
      loggedInUserCompanyName: undefined,
      leadSourceSearchTerm: undefined,
      checkoutPageSearchTerm: undefined,
      contractSearchTerm: undefined,
      checkoutPageDebounce: undefined,
      validationKey: undefined,
      distanceUpdateKey: undefined,
      dueDateKey: undefined,
      saveQuoteError: undefined,
      saveQuoteErrorMessage: undefined,
      showDecisionDateError: false,
      toggleSubTabs: 0,
      quotesFilters: filter(),
      reservationsFilters: filter(),
      pastQuotes: [],
      pastReservations: [],
      saveForLater: true,
      saveAndConvert: false,
      quoteClone: null,
      isCustomerSelected: false,
      isLeadSourceSelected: false,
      isCheckoutPageSelected: false,
      pastShowAll: false,
      showCheckoutPageError: false,
      showLeadSourceError: false,
      suggestedLeadSources: [],
      suggestedCheckoutPages: [],
      suggestedContracts: [],
      tripTypes: [],
      chargeTypes: [],
      vehicleTypes: [],
      rateTypes: [],
      paymentTypes: [],
      paymentMethodTypes: [],
      isTiersEnabled: false,
      isNewTripAmenitiesEnabled: false,
      showPastQuotes: false,
      showLastQuote: false,
      lastQuoteId: undefined,
      showMarketRatesDialog: false,
      showMarketsRatesMap: false,
      hasCustomerFieldInputs: false,
      tripPaymentMethodTypes: [],
      tripStagePaymentMethodTypes: {
        checkoutPaymentMethods: [],
        balancePaymentMethods: [],
      },
      savedPricingMarket: null,
      tripTemplate: {
        tripContact: {
          firstName: undefined,
          lastName: undefined,
          phone: undefined,
          email: undefined,
        },
        rates: [],
        charges: [],
        tripType: { id: null },
        paymentType: { id: null },
        checkoutType: { key: null, label: null },
        tripStatus: { id: 1 },
        drivingTime: 0,
        isManuallySelectedPricingMarket: false,
        pricingMarket: null,
        distance: 0,
        deadDistance: 0,
        pricingGarageId: null,
        passengerCount: null,
        requiredDrivers: null,
        hourlyDiff: 0,
        calendarDays: 1,
        dailyRate: 0,
        hourlyRate: 0,
        dueDate: null,
        processingFeePercentage: 3,
        marketplaceDiscountPercent: 0,
        marketplaceDiscountCharge: 0,
        marketplaceMarkupPercent: 0,
        marketplaceMarkupCharge: 0,
        depositPercentage: 10,
        newQuoteNoteText: null,
        newQuoteNoteHtml: null,
        recurrences: [],
        routeName: null,
        vehicleNeededEntireTrip: false,
        autoDetectedMarketId: null,
        nearestMarketId: null,
        requiredVehicles: [
          { vehicleType: { id: null }, quantity: null, vehicleId: null },
        ],
        stops: [
          {
            address: {},
            isPickupEstimated: false,
            isDropoffEstimated: false,
            tripID: null,
            createdOn: null,
            updatedOn: null,
            orderIndex: 0,
            isArrived: false,
            startDatetime: null,
            pickupDatetime: null,
            dropoffDatetime: null,
            stopType: 'Pickup',
          },
          {
            address: {},
            isPickupEstimated: false,
            isDropoffEstimated: false,
            tripID: null,
            createdOn: null,
            updatedOn: null,
            orderIndex: 1,
            isArrived: false,
            startDatetime: null,
            pickupDatetime: null,
            dropoffDatetime: null,
            stopType: 'Dropoff',
          },
        ],
        tripNotes: [],
        autoSetPaymentType: true,
      },
      total: null,
      recurringTotal: null,
      requiredDeposit: null,
      quote: {
        id: null,
        contractId: null,
        customEmail: {
          to: null,
          subject: null,
          opener: null,
          requested: null,
          closing: null,
          attachment: null,
          checkoutText: null,
        },
        currencyType: 'USD',
        customer: {
          id: null,
          firstName: '',
          lastName: '',
          organization: '',
          bookings: null,
          industryId: null,
          email: null,
          phone: null,
        },
        isPreBooking: false,
        isEnterprise: false,
        isExpirationDateTimeAutoset: this.isQuoteExpirationAutosetEnabled
          ? true
          : null,
        company: { id: null },
        createdByUser: { id: null }, // should not be passed from UI!!
        checkoutPage: { id: null, key: null, name: null },
        pricingMethod: null,
        leadSource: {
          id: 188,
          label: 'CharterUP',
          key: 'charterup',
          description: '',
          partnerCompanyId: 2,
          company: { id: 1 },
        },
        total: this.total,
        decisionDate: null,
        expirationDate: null,
        allowEcheckout: false,
        attachPdfToEmail: true,
        charterUpQuote: false,
        busNeededForEntireTrip: false,
        quoteNotes: [],
        productClassificationId: null,
        sourcingTeamClassificationId: null,
        supportTeamClassificationId: null,
        minQuality: null,
        tierId: null,
        tier: null,
        trips: [
          {
            istripContactSelected: false,
            tripContactIsCustomer: false,
            tripContactId: null,
            tripContact: {
              id: null,
              firstName: '',
              lastName: '',
              organization: '',
              bookings: null,
            },
            rates: [],
            charges: [],
            tripType: { id: null },
            checkoutType: { key: null, label: null },
            tripStatus: { id: 1 },
            distance: 0,
            deadDistance: 0,
            drivingTime: 0,
            dailyRate: 0,
            hourlyRate: 0,
            dueDate: null,
            pricingMarket: null,
            isManuallySelectedPricingMarket: false,
            passengerCount: null,
            requiredDrivers: null,
            hourlyDiff: 0,
            calendarDays: 1,
            requiredVehicles: [
              { vehicleType: { id: null }, quantity: null, vehicleId: null },
            ],
            processingFeePercentage: 3,
            marketplaceDiscountPercent: 0,
            marketplaceDiscountCharge: 0,
            marketplaceMarkupPercent: 0,
            marketplaceMarkupCharge: 0,
            depositPercentage: 10,
            paymentType: { id: null },
            stops: [
              {
                address: {},
                isPickupEstimated: false,
                isDropoffEstimated: false,
                tripID: null,
                createdOn: null,
                updatedOn: null,
                orderIndex: 0,
                isArrived: false,
                startDatetime: null,
                pickupDatetime: null,
                dropoffDatetime: null,
                stopType: 'Pickup',
              },
              {
                address: {},
                isPickupEstimated: false,
                isDropoffEstimated: false,
                tripID: null,
                createdOn: null,
                updatedOn: null,
                orderIndex: 1,
                isArrived: false,
                startDatetime: null,
                pickupDatetime: null,
                dropoffDatetime: null,
                stopType: 'Dropoff',
              },
            ],
            recurrences: [],
            routeName: null,
            vehicleNeededEntireTrip: false,
            tripNotes: [],
            autoSetPaymentType: true,
            tripAmenities: [],
          },
        ],
      },
      selectedTrip: 'trip-0',
      processingFeeDebounce: null,
      clearPricingTable: false,
      contract: null,
      activeTripIndex: 0,
      disableContractDetailFields: false,
      pricingData: [],
      lead: null,
      loadingQuoteReset: false,
      sidebarDialog: false,
      tripConflicts: [],
      cachedQuote: null,
      quoteChanged: false,
      unsavedChangesWarningDialog: false,
      newQuoteStopDatesInPastDialog: false,
      unsavedChangesLeaveAction: null,
      isEditingCustomer: false,
      formSubmitted: false,
      customerAccountDefaults: null,
      isQuoteTierAutoSelected: false,
      isCapturePaymentStagesEnabled: false,
    }
  },
  validations: {
    quote: {},
  },
  computed: {
    ...authComputed,
    ...callCenterComputed,
    ...mapGetters({
      quoteInProgress: 'quotes/quoteInProgress',
      createNewSelected: 'quotes/getCreateCustomerAccount',
      marketStoreChangesForMarketplaceSendToClient:
        'quotes/getSelectedMarketChanges',
      shouldSplitTrip: 'quotes/getShouldSplitTrip',
      isShuttleCreateQuoteEnabled: 'featureToggles/isShuttleCreateQuoteEnabled',
      isAccountExecutiveAndOpportunityIdEnabled:
        'featureToggles/isAccountExecutiveAndOpportunityIdEnabled',
      getIsManuallySelectedPricingMarket:
        'quotes/getIsManuallySelectedPricingMarket',
    }),
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeAdd() {
      return this.mode === 'add'
    },
    isActive() {
      if (this.quote?.isActive === false) {
        return false
      }
      return true
    },
    previousTripNotes() {
      let notes = this.quote.trips[this.selectedTripIndex]?.tripNotes
      if (notes) {
        let customerNotes = notes.find((note) => {
          return note.noteType === CUSTOMER_NOTE_TYPE
        })
        let officeNotes = notes.find((note) => {
          return note.noteType === INTERNAL_NOTE_TYPE
        })
        let driverNotes = notes.find((note) => {
          return note.noteType === DRIVER_NOTE_TYPE
        })

        let previousNotes = {}
        previousNotes.customerNotes =
          customerNotes?.html ||
          customerNotes?.note ||
          this.quote.trips[this.selectedTripIndex]?.description
        previousNotes.officeNotes =
          officeNotes?.html ||
          officeNotes?.note ||
          this.quote.trips[this.selectedTripIndex]?.notes
        previousNotes.driverNotes = driverNotes?.html || driverNotes?.note

        return previousNotes
      }
      return {}
    },
    recurrenceEventObjectForSelectedTrip() {
      const trip = this.quote.trips[this.selectedTripIndex]
      if (!trip) {
        return null
      }
      const name =
        trip.routeName || `Trip ${Number(this.selectedTripIndex) + 1}`
      return {
        id: trip.tripId,
        idKey: 'tripId',
        startDatetime: trip.stops[0]?.pickupDate
          ? `${trip.stops[0].pickupDate}T${
              trip.stops[0].pickupTime || '12:00'
            }:00`
          : null,
        endDatetime:
          trip.stops.length > 1 &&
          trip.stops[trip.stops.length - 1]?.dropoffDate
            ? `${trip.stops[trip.stops.length - 1].dropoffDate}T${
                trip.stops[trip.stops.length - 1].dropoffTime || '12:00'
              }:00`
            : null,
        name,
      }
    },
    sideBarToggled() {
      const state = this.getSideBar()
      if (state) {
        return 'why'
      }
      return 'not'
    },
    showingMarketplaceViewForClient() {
      return this.showMarketplaceFeatures && this.clientQuoteView
    },
    componentForClientQuoteView() {
      switch (this.showingMarketplaceViewForClient) {
        case 'bids':
          return QuoteDetail
        case 'confirmation':
          return QuoteConfirmation
        default:
          // FIXME: We should have the rest of the quote form
          // be treated in this same way.
          return null
      }
    },
    selectedTripIndex() {
      const selectedTrip = this.selectedTrip || ''
      return selectedTrip.substring(
        selectedTrip.indexOf('-') + 1,
        selectedTrip.length
      )
    },
    modeTitle() {
      let modeTitleString = 'Add New'
      if (this.isModeView) {
        modeTitleString = 'View'
      }
      if (this.isModeEdit) {
        modeTitleString = 'Edit'
      }
      return modeTitleString
    },
    canCreatePreBooking() {
      return findPermissionByName(
        this.currentUserProfile?.roles,
        'hasESSControl'
      )
    },
    canOpenCharterUpQuote() {
      return findPermissionByName(
        this.currentUserProfile?.roles,
        'canSendCharterUPQuote'
      )
    },
    showMarketplaceFeatures() {
      return (
        (this.quote.pricingMethod === 'bids' ||
          this.quote.pricingMethod === 'category') &&
        this.canOpenCharterUpQuote &&
        !this.quote.isPreBooking
      )
    },
    canViewMarketDetails() {
      return findPermissionByName(
        this.currentUserProfile?.roles,
        'canViewMarketDetails'
      )
    },
    canEditTiers() {
      return this.isTiersEnabled && findPermissionByName(
        this.currentUserProfile?.roles,
        'canEditTiers'
      )
    },
    createdOnDisplayText() {
      return this.formatDateTimeForDisplay(this.quote.createdOn)
    },
    updatedOnDisplayText() {
      return this.formatDateTimeForDisplay(this.quote.updatedOn)
    },
    trips: {
      get() {
        return this.quote.trips
      },
      set(newTrips) {
        return newTrips
      },
    },
    checkoutPageTypeString() {
      if (this.showMarketplaceFeatures) {
        return 'charterup'
      }
      return 'regular'
    },
    approveQuoteTypeString() {
      if (this.isBASQuote) {
        return 'bas'
      }
      return 'take-payment'
    },
    prequoteLeadDetected() {
      return this.isModeAdd && this.lead && this.isOnCall
    },
    preQuoteLeadCreatedOnDisplayText() {
      if (this.lead?.createdOn) {
        return DateTime.fromISO(this.lead.createdOn, {
          zone: this.currentUser.company.address.timeZone,
        }).toFormat('D hh:mm a')
      }
      return null
    },
    preQuoteLeadPickupDateDisplayText() {
      if (this.lead?.pickupDatetime) {
        return DateTime.fromISO(this.lead.pickupDatetime, {
          zone: this.currentUser.company.address.timeZone,
        })
          .toUTC()
          .toLocaleString(DateTime.DATE_SHORT)
      }
      return null
    },
    preQuoteLeadDropoffDateDisplayText() {
      if (this.lead?.dropoffDatetime) {
        return DateTime.fromISO(this.lead.dropoffDatetime, {
          zone: this.currentUser.company.address.timeZone,
        })
          .toUTC()
          .toLocaleString(DateTime.DATE_SHORT)
      }
      return null
    },
    shouldDisplayPastQuotesTable() {
      return (
        this.isCustomerSelected &&
        (this.showPastQuotes || this.showLastQuote) &&
        this.quote.customer.id
      )
    },
    isQuoteExpired() {
      if (
        this.quote.expirationDate === null ||
        this.quote.expirationTimezone === null
      ) {
        return false
      }

      const expiration = DateTime.fromISO(this.quote.expirationDate, {
        zone: this.quote.expirationTimezone,
      })
      const currentTime = DateTime.fromJSDate(new Date(), {
        zone: this.quote.expirationTimezone,
      })
      const secondsToExpire = expiration.diff(currentTime).as('seconds')

      return secondsToExpire <= 0
    },
    showLeadSourceButton() {
      return this.quote?.leadSource?.label !== 'CharterUP'
    },
    isQuoteExpirationAutosetEnabled() {
      const { getters = {} } = this.$store
      return getters['systemParameters/isQuoteExpirationAutosetEnabled']
    },
    availablePricingMethods() {
      if (!this.quote?.checkoutPage?.id || this.quote.checkoutPage.id === 4) {
        return PRICING_METHODS_MAP
      } else {
        return [PRICING_METHODS_MAP.find((item) => item.key === 'single_price')]
      }
    },
    pricingMethodLabel() {
      if (!this.quote?.pricingMethod) {
        return ''
      }
      return (
        PRICING_METHODS_MAP.find(
          (item) => item.key === this.quote.pricingMethod
        )?.label || this.quote.pricingMethod
      )
    },
    otherConfigsFlexProps() {
      if (this.canEditTiers && this.canCreatePreBooking) {
        return { md12: true, lg8: true }
      }
      return { md8: true, lg6: true }
    },
    tiers() {
      return this.getTiers()
    },
    quoteTier() {
      if (this.quote.tierId) {
        return this.quote.tier
      }
      return null
    },
    showTierMarker() {
      if (this.isTiersEnabled && this.quote.tierId) {
        return this.quote.tier?.level > 1
      }
      return false
    },
  },
  watch: {
    quote: {
      deep: true,
      handler: 'handleQuoteChange',
    },
    contract() {
      this.quote.contractId =
        this.contract?.contractId || this.contract?.quoteId
    },
    async leadSourceSearchTerm(value) {
      await this.searchLeadSourceList(value)
    },
    async checkoutPageSearchTerm(value) {
      await this.searchCheckoutPagesList(value)
    },
    async contractSearchTerm(value) {
      await this.searchContractsList(value)
    },
    selectedTrip() {
      this.toggleSubTabs = 0
    },
    '$store.state.split.isReady': {
      async handler(value) {
        if (!value) {
          return
        }

        this.isCapturePaymentStagesEnabled = await this.isFeatureEnabled(
          SplitFeatureFlag.CapturePaymentStages
        )

        this.isTiersEnabled = await this.isFeatureEnabled(
          SplitFeatureFlag.ServiceTier
        )

        this.isNewTripAmenitiesEnabled = await this.isFeatureEnabled(
          SplitFeatureFlag.NewTripAmenities
        )
      },
      immediate: true,
    },
    'quote.quoteId'() {
      if (this.isModeView) {
        return
      }
    },
    'quote.isEnterprise'(newVal) {
      if (!newVal) {
        this.quote.accountExecutiveId = null
        this.quote.accountExecutiveName = null
        this.quote.opportunityId = null
      }
    },
    // Maintain charterUpQuote value but slowly de-reference it over time
    'quote.pricingMethod'(newVal) {
      this.quote.charterUpQuote = newVal === 'bids' || newVal === 'category'
    },
    marketStoreChangesForMarketplaceSendToClient(markets) {
      for (const [keyTripIndex, market] of Object.entries(markets)) {
        if (market) {
          this.trips[keyTripIndex].pricingMarket = market.marketId
        }
      }
    },
    $route: async function (to, from) {
      if (to.path === from.path) {
        return
      }

      this.isTiersEnabled = await this.isFeatureEnabled(
        SplitFeatureFlag.ServiceTier
      )
      if (this.isTiersEnabled) {
        await this.fetchAllTiers()
      }

      this.isCapturePaymentStagesEnabled = await this.isFeatureEnabled(
        SplitFeatureFlag.CapturePaymentStages
      )

      this.isNewTripAmenitiesEnabled = await this.isFeatureEnabled(
        SplitFeatureFlag.NewTripAmenities
      )

      if (
        to.params.id?.toString() === from.params.id?.toString() &&
        !to.params?.subRoute
      ) {
        return
      }
      Object.assign(this.$data, this.$options.data())
      if (
        to.name === 'quotes.view' &&
        (from.name === 'quotes.add' || from.name === 'quotes.edit')
      ) {
        this.resetQuoteForm(false, false)
      } else {
        this.resetQuoteForm()
      }

      await this.populateLeadSource()
      this.$validator?.reset()
      this.$refs['customer-form'].$refs.form.resetValidation()
    },
    prequoteLeadDetected(value) {
      if (value) {
        this.fillLeadSourceFromLead()
      }
    },
    availablePricingMethods(pricingMethodsList) {
      if (pricingMethodsList?.length === 1) {
        this.quote.pricingMethod = pricingMethodsList[0].key
      }
    },
    'quote.customer.customerId'(value) {
      if (value && this.isModeAdd) {
        this.loadCustomerAccountDefaults(value)
        this.loadCustomerServiceTier(value)
      }
    },
    isCustomerSelected(value) {
      if (!value && this.isQuoteTierAutoSelected) {
        this.quote.tierId = null
        this.quote.tier = null
        this.isQuoteTierAutoSelected = false
      }
    },
  },
  async mounted() {
    this.clearQuoteModule()
    let scrubRecurrenceIds = true

    if (this.isModeEdit || this.isModeView) {
      scrubRecurrenceIds = false
    }
    this.resetQuoteForm(false, scrubRecurrenceIds)

    const contractId = this.$route?.query?.contractId
    if (contractId) {
      this.populateContractDetails(contractId)
    }

    this.isTiersEnabled = await this.isFeatureEnabled(
      SplitFeatureFlag.ServiceTier
    )
    if (this.isTiersEnabled) {
      await this.fetchAllTiers()
    }
    this.isCapturePaymentStagesEnabled = await this.isFeatureEnabled(
      SplitFeatureFlag.CapturePaymentStages
    )
    this.isNewTripAmenitiesEnabled = await this.isFeatureEnabled(
      SplitFeatureFlag.NewTripAmenities
    )

    await this.populateLeadSource()

    this.searchCheckoutPagesList('')

    this.setDefaultValues()

    setTimeout(() => {
      this.quoteChanged = false
      this.cachedQuote = deepClone(this.quote)
    }, 3500)

    EventBus.$on('refreshQuote', () => {
      this.resetQuoteForm(false, false)
    })
    EventBus.$on('set-trip-pricing-market-manually-selected', (tripIndex) => {
      this.quote.trips[tripIndex].isManuallySelectedPricingMarket = true
    })
    EventBus.$on('shallow-merge-trip-data', (tripIndex, tripData) => {
      if (this.quote.trips[tripIndex]) {
        Object.assign(this.quote.trips[tripIndex], tripData)
      }
    })
    EventBus.$on('set-quote-pricing-time-label', (pricingTimeLabel) => {
      this.quote.pricingTimeLabel = pricingTimeLabel
    })
    EventBus.$on('set-trip-payment-method', (tripIndex, paymentStageKey, paymentMethodTypeId, isAllowed) => {
      this.handlePaymentMethodSelection(tripIndex, paymentStageKey, paymentMethodTypeId, isAllowed)
    })
  },
  methods: {
    ...mapGetters({
      getSideBar: 'dashboard/getSideBar',
      getTiers: 'tiers/getTiers',
    }),
    ...mapActions({
      clearQuoteModule: 'quoteForm/clearQuote',
      getQuoteTypes: 'types/getQuoteTypes',
      getCustomerAccounts: 'quotes/getCustomerAccounts',
      getV2Quote: 'quotes/getV2Quote',
      updateV2Quote: 'quotes/updateV2Quote',
      createV2Quote: 'quotes/createV2Quote',
      showAlert: 'app/showAlert',
      convert: 'quotes/convert',
      getQuoteTripEstimations: 'quotes/getQuoteTripEstimations',
      enableCampaign: 'quotes/changeEmailCampaignStatus',
      clearSelectedMarkets: 'quotes/clearSelectedMarkets',
      getSplitTrips: 'quotes/getSplitTrips',
      setShouldSplitTrip: 'quotes/setShouldSplitTrip',
      getMarketplaceLink: 'quotes/getMarketplaceLink',
      isFeatureEnabled: 'split/isFeatureEnabled',
      fetchAllTiers: 'tiers/fetchAllTiers',
    }),
    isRequired,
    isNotEmpty,
    phoneFormatFilter,
    // Used by the "Set to CharterUP" button to set the lead source directly to CharterUP without using suggestedLeadSoources
    async setLeadSource() {
      const filterObject = {
        column: {
          _t_id: 'lead_source_search_id',
          prop: 'displayName',
          filterType: 'contains',
          filterAsIs: true,
        },
        value: 'CharterUP',
      }
      const leadSourceFilter = filter()
      const parentFilter = leadSourceFilter.createParent('and')
      leadSourceFilter.add(parentFilter, filterObject)
      const params = {
        filters: leadSourceFilter.asQueryParams(),
        pageSize: 1,
      }
      const matchedLeadSources = await leadSources.getLeadSources(params)
      let charterUPLeadSource = matchedLeadSources?.data?.resultList || []
      charterUPLeadSource = charterUPLeadSource.filter(
        (suggestedLeadSource) => suggestedLeadSource.internalName
      )[0]
      this.selectLeadSourceFromList(charterUPLeadSource)
      this.quote.charterUpQuote = true
      this.quote.leadSourceTypeId = this.quote.leadSource.id
    },
    populateCustomerDetails(user) {
      this.quote.customer.firstName = user?.firstName
      this.quote.customer.lastName = user?.lastName
      this.quote.customer.phone = user?.phone
      this.quote.customer.email = user?.email
      this.quote.customer.organization = user?.companyName
    },
    updateTripProcessingPercentage(tripIndex, event) {
      if (this.processingFeeDebounce) {
        clearTimeout(this.processingFeeDebounce)
      }
      this.processingFeeDebounce = setTimeout(() => {
        const processingFee = event
        if (processingFee > 100) {
          this.quote.trips[tripIndex].processingFeePercentage = 100
        } else if (processingFee < 0) {
          this.quote.trips[tripIndex].processingFeePercentage = 0
        } else {
          this.quote.trips[tripIndex].processingFeePercentage = Math.round(
            processingFee
          )
        }
      }, 500)
    },
    handleUnsavedChanges() {
      this.unsavedChangesWarningDialog = true
    },
    confirmLeave() {
      this.unsavedChangesWarningDialog = false
      this.unsavedChangesLeaveAction()
    },
    confirmStay() {
      this.unsavedChangesWarningDialog = false
      this.unsavedChangesLeaveAction(false)
    },
    confirmQuoteCreate() {
      this.newQuoteStopDatesInPastDialog = false
      this.lockSaveButton = true
      setTimeout(
        async () =>
          {this.createV2QuoteWrapper(this.saveForLater, this.quoteClone, this.saveAndConvert)

          },
          1000
        )
      this.lockSaveButton = false
    },
    confirmCancel() {
      this.newQuoteStopDatesInPastDialog = false
      this.lockSaveButton = false
    },
    updateTripNoteText(value) {
      this.updateNotesForTripIndex(
        this.selectedTripIndex,
        CUSTOMER_NOTE_TYPE,
        value
      )
    },
    updateTripNoteHtml(value) {
      this.updateHtmlNotesForTripIndex(
        this.selectedTripIndex,
        CUSTOMER_NOTE_TYPE,
        value
      )
    },
    updateOfficeNoteText(value) {
      this.updateNotesForTripIndex(
        this.selectedTripIndex,
        INTERNAL_NOTE_TYPE,
        value
      )
    },
    updateOfficeNoteHtml(value) {
      this.updateHtmlNotesForTripIndex(
        this.selectedTripIndex,
        INTERNAL_NOTE_TYPE,
        value
      )
    },
    updateDriverNoteText(value) {
      this.updateNotesForTripIndex(
        this.selectedTripIndex,
        DRIVER_NOTE_TYPE,
        value
      )
    },
    updateDriverNoteHtml(value) {
      this.updateHtmlNotesForTripIndex(
        this.selectedTripIndex,
        DRIVER_NOTE_TYPE,
        value
      )
    },
    updateNotesForTripIndex(tripIndex, noteType, note) {
      if (noteType == CUSTOMER_NOTE_TYPE) {
        this.quote.trips[tripIndex].description = note
      }
      if (noteType == INTERNAL_NOTE_TYPE) {
        this.quote.trips[tripIndex].notes = note
      }

      let filteredNotes = this.quote.trips[tripIndex]?.tripNotes?.find(
        (note) => note.noteType === noteType
      )
      if (filteredNotes) {
        filteredNotes.note = note
      } else {
        const noteObj = {
          note: note,
          createdBy: this?.currentUser?.userId,
          noteType: noteType,
        }
        this.quote.trips[tripIndex].tripNotes.push(noteObj)
      }
    },
    updateHtmlNotesForTripIndex(tripIndex, noteType, html) {
      let filteredNotes = this.quote.trips[tripIndex]?.tripNotes?.find(
        (note) => note.noteType === noteType
      )
      if (filteredNotes) {
        filteredNotes.html = html
      } else {
        const noteObj = {
          html: html,
          createdBy: this?.currentUser?.userId,
          noteType: noteType,
        }
        this.quote.trips[tripIndex].tripNotes.push(noteObj)
      }
    },
    addAmenity(amenity) {
      this.quote.trips[this.selectedTripIndex].tripAmenities.push(amenity)
    },
    removeAmenity(amenity) {
      const index = this.quote.trips[this.selectedTripIndex].tripAmenities.findIndex(a => a.id === amenity.id)
      if (index >= 0) {
        this.quote.trips[this.selectedTripIndex].tripAmenities.splice(index, 1)
      }
    },
    setAmenityNoteHtml(amenityId, html) {
      const amenity = this.quote.trips[this.selectedTripIndex].tripAmenities.find(amenity => amenity.id === amenityId)
      if (amenity) {
        if (!amenity.note) {
          amenity.note = {
            html,
            createdBy: this.currentUser?.userId
          }
        } else {
          amenity.note.html = html
        }
      }
    },
    setAmenityNoteText(amenityId, text) {
      const amenity = this.quote.trips[this.selectedTripIndex].tripAmenities.find(amenity => amenity.id === amenityId)
      if (amenity) {
        if (!amenity.note) {
          amenity.note = {
            note: text,
            createdBy: this.currentUser?.userId
          }
        } else {
          amenity.note.note = text
        }
      }
    },
    accountExecutiveSelected(user) {
      this.quote.accountExecutiveId = user.userId
      this.quote.accountExecutiveName = `${user.firstName} ${user.lastName}`
      this.showAccountExecutiveError = false
    },
    async handleQuoteChange(quoteValue) {
      let allTripRecurringTotal = 0
      let allTripDeposit = 0
      let allTripTotal = 0
      const { trips = [] } = quoteValue
      // FIXME: for-await isn't yet supproted by vite, fyi. If we try to use
      // vite, we are going to need to switch to Promise.all or some such.
      for await (const trip of trips) {
        const { depositPercentage = 0 } = trip
        const { tripTotal, recurringTripTotal } = await this.singleTripTotal(
          trip
        )
        allTripRecurringTotal += recurringTripTotal
        allTripTotal += tripTotal
        if (trip.paymentType.id === 2) {
          allTripDeposit += recurringTripTotal * (depositPercentage / 100)
        } else if (trip.paymentType.id === 1) {
          allTripDeposit += recurringTripTotal
        }
      }
      this.recurringTotal = allTripRecurringTotal
      this.total = allTripTotal
      this.quote.total = allTripTotal
      this.requiredDeposit = allTripDeposit

      if (JSON.stringify(this.cachedQuote) !== JSON.stringify(this.quote)) {
        this.quoteChanged = true
      } else {
        this.quoteChanged = false
      }
    },
    async loadCustomerAccountDefaults(customerId) {
      const defaultsResponse = await customers.getCustomerAccountDefaultsByCustomer(
        customerId
      )
      this.customerAccountDefaults = defaultsResponse.data
      this.setCustomerAccountDefaultValues()
    },
    async loadCustomerServiceTier(customerId) {
      const serviceTierResponse = await customers.getCustomerServiceTier(
        customerId
      )
      if (serviceTierResponse?.data) {
        this.setQuoteTier(serviceTierResponse.data)
      }
    },
    async resetQuoteForm(maintainLoading = false, scrubRecurrenceIds = true) {
      this.loadingQuoteReset = true
      this.lastQuoteId = null
      this.setShouldSplitTrip(false)
      this.clearSelectedMarkets()
      await this.getCustomerAccounts()
      this.loggedInUserCompanyName =
        this.currentUser?.companyName || 'CharterUP'

      if (this.$route.params.id) {
        this.quoteId = parseInt(this.$route.params.id, 10)
        const quoteResult = await this.getV2Quote(this.quoteId)
        const quote = quoteResult?.data?.quote
        if (quote) {
          if (
            this.isShuttleCreateQuoteEnabled &&
            quote.trips.some((trip) => trip?.tripVehicleGroups?.length)
          ) {
            this.quoteChanged = false
            this.$router.push({
              name: `quotes.new`,
              params: {
                id: quote.quoteId,
                mode: this.mode,
              },
            })
          }
          this.scrubQuoteFromAPI(quote, scrubRecurrenceIds)
          this.quote = quote
          this.isConverted = this.quote.isConverted
          const { trips = [] } = this.quote
          this.isBASQuote = trips.some((trip) => trip?.paymentType?.id === 3)
          this.showApproveQuote = trips.some(
            (trip) => trip?.paymentType?.id === 3
          )
          this.trips.forEach((trip) => {
            const pricingMarketId = trip?.pricingMarket
            trip.savedPricingMarket = pricingMarketId
          })
          if (this.quote.charterUpQuote) {
            this.alreadyInvitedToCharterUp = true
          }
          setTimeout(() => {
            this.distanceUpdateKey = v4()
          }, 1000)
        }
      } else if (this.$route.query.d) {
        const quoteResult = await this.getV2Quote(this.$route.query.d)
        const quote = quoteResult?.data?.quote
        if (quote) {
          if (
            this.isShuttleCreateQuoteEnabled &&
            quote.trips.some((trip) => trip?.tripVehicleGroups?.length)
          ) {
            this.quoteChanged = false
            this.$router.push({
              name: `quotes.new`,
              params: {
                mode: this.mode,
              },
              query: {
                d: quote.quoteId,
              },
            })
          }
          this.scrubQuoteFromAPI(quote, scrubRecurrenceIds)
          delete quote.id
          delete quote.quoteId
          delete quote.createdOn
          delete quote.updatedOn
          delete quote.selfServeId
          quote.isConfirmed = false
          quote.isConverted = false
          quote.isSelfServe = false
          quote.leadTemperatureTypeId = null
          quote.leadFollowUpDate = DateTime.local().plus({ days: 2 })
          quote.quoteNotes = []
          this.isConverted = quote.isConverted
          const { trips = [] } = quote
          this.isBASQuote = trips.some((trip) => trip?.paymentType?.id === 3)
          for (const trip of trips) {
            this.duplicateTrip(trip)
          }
          this.trips = trips
          this.showApproveQuote = trips.some(
            (trip) => trip?.paymentType?.id === 3
          )
          if (quote.charterUpQuote) {
            this.alreadyInvitedToCharterUp = true
          }
          this.quote = quote
          setTimeout(() => {
            this.distanceUpdateKey = v4()
          }, 1000)
        }
      } else {
        this.quote.total = this.total
        this.quote = Object.assign({}, this.quote, this.quoteInProgress)
      }

      this.$nextTick(() => this.getPricing())

      if (this.isModeEdit && this.quote.quoteId) {
        this.oldQuote = '' + this.quote.quoteId
      }
      if (typeof this.quote.busNeededForEntireTrip !== 'boolean') {
        this.quote.busNeededForEntireTrip = false
      }
      const quoteTypesData = await this.getQuoteTypes().catch((e) => e)
      const quoteTypes = quoteTypesData.data || {}
      this.paymentTypes = quoteTypes.payments
      this.rateTypes = quoteTypes.rates
      this.vehicleTypes = quoteTypes.vehicleTypes.filter(
        (vehicleType) => vehicleType.key !== 'unassigned'
      )
      this.tripTypes = quoteTypes.trips
      this.chargeTypes = quoteTypes.charges
      this.paymentMethodTypes = quoteTypes.paymentMethods

      const validPaymentMethodTypes = [
        PaymentMethodTypeKey.ACH,
        PaymentMethodTypeKey.CreditCard,
        PaymentMethodTypeKey.Check,
      ]
      this.paymentMethodTypes = this.paymentMethodTypes.filter(
        (paymentMethod) => validPaymentMethodTypes.includes(paymentMethod.key)
      )
      const validPaymentTypes = [
        PaymentTypeKey.FullPayment,
        PaymentTypeKey.DownPayment,
        PaymentTypeKey.BillAfterServices,
      ]
      this.paymentTypes = this.paymentTypes.filter((paymentType) =>
        validPaymentTypes.includes(paymentType.key)
      )

      this.chargeTypes = this.chargeTypes.map((chargeType) => {
        if (
          ['processing_fees', 'trip_change', 'refund_adjustment'].includes(
            chargeType.key
          )
        ) {
          chargeType.hidden = true
        }
        return chargeType
      })
      this.tripPaymentMethodTypes = this.paymentMethodTypes.map(
        (paymentMethodType) => {
          return {
            isAllowed: false,
            paymentMethodTypeId: paymentMethodType.id,
            paymentMethodType,
          }
        }
      )
      const stagePaymentMethodTypes = this.paymentMethodTypes.map(
        (paymentMethodType) => {
          return {
            isAllowed: false,
            paymentMethodId: paymentMethodType.id,
          }
        }
      )
      stagePaymentMethodTypes.sort(
        (a, b) => a.paymentMethodId - b.paymentMethodId
      )
      this.tripStagePaymentMethodTypes.checkoutPaymentMethods = deepClone(
        stagePaymentMethodTypes
      )
      this.tripStagePaymentMethodTypes.balancePaymentMethods = deepClone(
        stagePaymentMethodTypes
      )
      if (this.isModeAdd) {
        const firstTrip = this.quote?.trips?.[0]
        const hasFirstTripPaymentMethodTypes =
          firstTrip?.paymentMethods && firstTrip?.stagePaymentMethods
        if (!hasFirstTripPaymentMethodTypes) {
          firstTrip.paymentMethods = deepClone(this.tripPaymentMethodTypes)
          firstTrip.stagePaymentMethods = deepClone(
            this.tripStagePaymentMethodTypes
          )
          if (this.customerAccountDefaults) {
            const existingMethod = firstTrip?.paymentMethods?.find(
              (method) =>
                method?.paymentMethodType?.key ===
                this.customerAccountDefaults.paymentMethodType?.key
            )
            if (existingMethod) {
              for (const method of firstTrip?.paymentMethods || []) {
                method.isAllowed = false
              }
              existingMethod.isAllowed = true
              for (const method of firstTrip?.stagePaymentMethods
                ?.checkoutPaymentMethods || []) {
                method.isAllowed =
                  method.paymentMethodId === existingMethod.paymentMethodTypeId
              }
              for (const method of firstTrip?.stagePaymentMethods
                ?.balancePaymentMethods || []) {
                method.isAllowed =
                  method.paymentMethodId === existingMethod.paymentMethodTypeId
              }
            }
          }
        }
      }

      this.$refs['customer-form'].resetCustomerFilters()
      this.trips = this.quote.trips

      if (this.currentUser.company.checkoutTypeId === 1) {
        this.quote.allowEcheckout = this.quote.allowEcheckout || true
        this.quote.attachPdfToEmail = this.quote.attachPdfToEmail || false
      } else {
        this.quote.allowEcheckout = false
        this.quote.attachPdfToEmail = true
        this.onlyOption = true
      }
      if (this.quote.contractId && (this.isModeView || this.isModeEdit)) {
        const contract = await contracts
          .getContract(this.quote.contractId)
          .catch((error) => {
            logger.error(error)
          })
        this.suggestedContracts.push(contract.data)
        this.contract = contract.data
      }
      if (!maintainLoading) {
        this.loadingQuoteReset = false
      }
      const userResponse = await this.$store.dispatch(
        'users/getUser',
        this.quote.createdById || this.currentUser.userId
      )
      const userData = userResponse.data
      if (userData.user) {
        this.quote.createdBy = userData.user
      }
      this.$nextTick(() => this.getPricing())
    },
    async populateLeadSource() {
      if (this.isModeAdd) {
        if (this.callSid) {
          try {
            const leadResponse = await leads.getLeadByCallSid(this.callSid)
            this.lead = leadResponse.data.lead
          } catch (e) {
            logger.error(e)
          }
        }

        // set CharterUP as default unless the agent fills details from a detected lead
        await this.setCharterUpAsLeadSource()
        this.quote.charterUpQuote = true
      }
    },
    async setCharterUpAsLeadSource() {
      await this.searchLeadSourceList('CharterUP')
      let timeout = setInterval(() => {
        if (this.suggestedLeadSources.length) {
          this.selectLeadSourceFromList(this.suggestedLeadSources[0])
          clearInterval(timeout)
        }
      }, 50)
    },
    fillLeadSourceFromLead() {
      this.quote.leadSource = this.lead.leadSourceType
      this.leadSourceSearchTerm = this.lead.leadSourceType.label
    },
    populateFromLead() {
      this.fillLeadSourceFromLead()
      this.populatedFromLead = true

      // populate user selector and search for user
      this.quote.customer.firstName = this.lead.firstName
      this.quote.customer.lastName = this.lead.lastName
      this.quote.customer.phone = this.lead.phone
      this.quote.customer.organization = this.lead.organization
      this.$refs['customer-form'].searchCustomerDetail(
        'firstName',
        this.quote.customer.firstName
      )

      // populate pickup date from lead
      this.quote.trips[0].stops[0].pickupDate = this.lead.pickupDatetime.substring(
        0,
        10
      )
      this.quote.trips[0].stops[0].pickupTime = '12:00'
      this.$refs['quote-form-trip-detail']?.dateChange(
        this.quote.trips[0].stops[0],
        0,
        'pickup'
      )
    },
    setDateTimes({ decisionDate, expirationDate, expirationTimezone }) {
      if (decisionDate) {
        this.quote.decisionDate = decisionDate
      }
      if (expirationDate) {
        this.quote.expirationDate = expirationDate
      }
      if (expirationTimezone) {
        this.quote.expirationTimezone = expirationTimezone
      }
    },
    addTrip() {
      this.quote.trips.push(
        Object.assign(
          {},
          Object.assign({}, deepClone(this.tripTemplate), {
            paymentMethods: deepClone(this.tripPaymentMethodTypes),
            stagePaymentMethods: deepClone(this.tripStagePaymentMethodTypes),
          })
        )
      )
    },
    removeTrip(tripIndex) {
      this.quote.trips.splice(tripIndex, 1)
      this.selectedTrip = 'trip-0'
    },
    async getPricing() {
      if (typeof this.pricingDebounce !== 'undefined') {
        clearTimeout(this.pricingDebounce)
      }
      this.calculatingEstimations = true
      this.pricingDebounce = setTimeout(async () => {
        let quoteClone = deepClone(this.quote)
        quoteClone = scrubQuoteForAPI(
          quoteClone,
          true,
          this.isCapturePaymentStagesEnabled
        )

        //When viewing or editing the quote,
        //don't override existing trip pricing markets
        quoteClone.trips.forEach((trip) => {
          if (!!trip.active) {
            trip.isManuallySelectedPricingMarket =
              ['view', 'edit'].includes(this.mode) ||
              trip.isManuallySelectedPricingMarket
          }
        })

        const estimationsData = await this.getQuoteTripEstimations(
          quoteClone
        ).catch((e) => e)
        const estimationLists = estimationsData?.data || []
        const { trips = [] } = this.quote
        estimationLists.forEach((estimation) => {
          const trip = trips?.[estimation.tripId] || {}
          trip.estimatedTimeLabel = estimation.estimatedTimeLabel
          trip.deadMileLabel = estimation.deadMileLabel
          trip.liveMileLabel = estimation.liveMileLabel
          trip.deadDistance = estimation.deadDistance
          trip.hourlyDiff = estimation.itineraryHours
          this.$set(trip, 'nearestMarketId', estimation.nearestMarketId)
          this.$set(
            trip,
            'autoDetectedMarketId',
            estimation.autoDetectedMarketId
          )
        })
        this.$nextTick(() => {
          this.quote.trips = trips
          this.trips = trips
          this.calculatingEstimations = false
          this.$forceUpdate()
        })
      }, 1000)
    },
    async saveQuoteHandler(
      saveForLater = false,
      marketSelected = false,
      saveAndConvert = false,
      soldOut = false,
      isPendingConfirmation = false,
      isQuoteStopDateInPast = false
    ) {
      if (this.isModeEdit && this.quoteId) {
        const mostRecentQuoteDetails = await this.getV2Quote(this.quoteId)
        if (mostRecentQuoteDetails?.data?.quote?.isConverted) {
          this.lockSaveButton = true
          this.resetQuoteForm()
          this.$router.push({
            name: 'quotes.view',
            params: { id: this.quoteId },
          })
          this.showAlert({
            message: `Changes failed to save. Quote ${this.quoteId} has already been converted.`,
            type: 'error',
          })
          return
        }
      }
      this.validationResults = {}
      this.formSubmitted = true
      this.hasCustomerFieldInputs = true
      if (this.$refs['customer-form'].validate() && !this.quote?.customer?.id) {
        await this.$refs['customer-form'].submitNewCustomerForm(true)
      }
      this.$nextTick(() => {
        if (!this.$refs['customer-form'].validate()) {
          this.validationResults['local-customer-form'] = {
            hasFailures: true,
          }
        } else {
          delete this.validationResults['local-customer-form']
        }
        this.showLeadSourceError = false
        this.showCheckoutPageError = false
        if (
          this.quote?.checkoutPage?.id === null ||
          this.quote?.leadSource?.id === null ||
          (this.quote?.charterUpQuote && !this.quote?.decisionDate)
        ) {
          if (this.quote?.checkoutPage?.id === null) {
            this.showCheckoutPageError = true
            this.validationResults['local-leadsource-checkoutpage-form'] = {
              hasFailures: true,
            }
          }
          if (this.quote?.leadSource?.id === null) {
            this.showLeadSourceError = true
            this.validationResults['local-leadsource-checkoutpage-form'] = {
              hasFailures: true,
            }
          }
          if (
            !this.$refs['quote-form-footer'].$refs[
              'quote-footer-form'
            ].validate() &&
            this.quote.charterUpQuote &&
            !this.quote.decisionDate
          ) {
            this.showDecisionDateError = true
            this.validationResults['quote-form-footer'] = {
              hasFailures: true,
            }
          }
        } else {
          delete this.validationResults['local-leadsource-checkoutpage-form']
        }
        if (
          this.$refs['quote-form-configurations'] &&
          !this.$refs['quote-form-configurations'].validate()
        ) {
          this.validationResults['quote-form-configurations'] = {
            hasFailures: true,
          }
        }
        if (!this.$refs['quote-form-lead-follow-up'].validate()) {
          this.validationResults['lead-follow-up-detail'] = {
            hasFailures: true,
          }
        }
        if (this.quote?.isEnterprise && !this.quote?.accountExecutiveId) {
          this.showAccountExecutiveError = true
          this.validationResults['quote-form-enterprise-fields'] = {
            hasFailures: true,
          }
        }
        if (
          !this.$refs['quote-form-enterprise-fields'].validate() &&
          this.isAccountExecutiveAndOpportunityIdEnabled
        ) {
          this.validationResults['quote-form-enterprise-fields'] = {
            hasFailures: true,
          }
        }
        if (!this.$refs['quote-form-classifications'].validate()) {
          this.validationResults['quote-form-classifications'] = {
            hasFailures: true,
          }
        }
        if (
          !this.quote?.expirationDate &&
          this.quote?.checkoutPage?.label === 'CharterUP' &&
          marketSelected &&
          this.quote?.charterUpQuote
        ) {
          this.showExpirationDateError = true
          this.validationResults['expiration-date'] = {
            hasFailures: true,
          }
        }
        this.validationKey = v4() // trigger validations for child components

        this.$refs['quote-form-trip-detail'].forEach(
          (tripFooter, tripIndex) => {
            if (this.quote.charterUpQuote) {
              const valid =
                tripFooter.$refs['quote-form-calculations'].validate() &&
                this.quote.trips[tripIndex].pricingMarket !== null &&
                this.quote.trips[tripIndex].pricingMarket !== undefined
              this.validationResults[`${tripIndex}-market`] = {
                hasFailures: !valid,
              }
            } else {
              this.validationResults[`${tripIndex}-market`] = {
                hasFailures: false,
              }
            }
          }
        )

        if (this.newQuoteNoteText) {
          this.quote.quoteNotes.push({
            note: this.newQuoteNoteText,
            html: this.newQuoteNoteHtml,
          })
          this.newQuoteNoteText = null
          this.newQuoteNoteHtml = null
        }
        this.quote.soldOut = soldOut
        this.quote.isPendingConfirmation = isPendingConfirmation

        const quoteClone = deepClone(this.quote)
        if (this.saveQuoteDebounce) {
          clearTimeout(this.saveQuoteDebounce)
        }
        this.saveQuoteDebounce = setTimeout(async () => {
          const hasFailures = Object.keys(this.validationResults).some((key) =>
            op(this.validationResults, `${key}/hasFailures`)
          )
          if (hasFailures) {
            this.lockSaveButton = false
            this.scrollToFirstFormError()
            return logger.warn(
              'Form validation results will not allow quote to be saved.'
            )
          } else {
            this.lockSaveButton = true
          }

          this.scrubQuote(quoteClone)
          const quoteCloneForAPI = await scrubQuoteForAPI(
            deepClone(quoteClone),
            false,
            this.isCapturePaymentStagesEnabled
          )
          this.isBASQuote = quoteCloneForAPI.trips.some(
            (trip) => trip?.paymentType?.id === 3
          )
          if (quoteCloneForAPI.trips && quoteCloneForAPI.trips.length > 0) {
            quoteCloneForAPI.trips.forEach(async (trip) => {
              // trip.drivingTime = trip.drivingTime + (trip.deadSeconds ? trip.deadSeconds : 0)
              // CHECK IF THERE IS AN ID. IF THERE IS NOT, CREATE A NEW CUSTOMER.
              if (!trip.tripContactId && trip.tripContact.email) {
                const addCustomerResponse = await customers.addCustomer({
                  payload: trip.tripContact,
                })
                if (addCustomerResponse.status === 200) {
                  await this.$refs['customer-form'].searchCustomerDetail(
                    'email',
                    trip.tripContact.email,
                    true
                  )
                }
              }
            })
          }
          if (
            this.isModeEdit ||
            this.isModeView ||
            this.showingMarketplaceViewForClient
          ) {
            // An existing quote
            const emailPreference = this.getEmailPreference(saveForLater)
            const params = {
              emailPreference,
              quoteId: this.quoteId,
              payload: quoteCloneForAPI,
            }
            try {
              const savedQuoteResults = await this.updateV2Quote(params)

              if (savedQuoteResults.status !== 200) {
                this.saveQuoteError = true
                this.saveQuoteErrorMessage =
                  'There was an error saving this quote.'
              } else {
                if (saveAndConvert) {
                  const approveQuotePayload = {
                    meta: { billing: {} },
                    isManual: true,
                    payment_method: 'check',
                  }
                  const params = {
                    quoteId: this.quoteId,
                    payload: approveQuotePayload,
                  }

                  const approveQuoteResponse = await this.convert(params).catch(
                    (e) => e.response
                  )
                  if (approveQuoteResponse.status !== 200) {
                    this.saveQuoteError = true
                    this.saveQuoteErrorMessage =
                      'There was an error saving this quote.'
                    return
                  }
                }

                if (this.showMarketplaceFeatures && !saveAndConvert) {
                  await this.resetQuoteForm(false, false)
                  if (!this.quote.isConfirmed) {
                    this.$router.push({
                      path: `/quotes/view/${this.quoteId}/confirmation`,
                    })
                  } else {
                    this.$router.push({
                      name: 'quotes.view',
                      params: { id: this.quote.quoteId, subRoute: 'bids' },
                    })
                  }
                } else {
                  this.$router.push({
                    name: 'quotes.view',
                    params: { id: this.quoteId },
                  })
                }

                this.showAlert({
                  message: `Quote ${this.quoteId} saved successfully.`,
                  type: 'success',
                  actionText: 'View Quote',
                  actionPath: `/quotes/view/${this.quoteId}`,
                  actionId: 'app-alert-button-action-quote-view',
                })
              }
            } catch (e) {
              this.lockSaveButton = false
              this.showAlert({
                message: e.response?.data?.message || `Quote failed to save.`,
                type: 'error',
              })
              throw e
            }
          } else {
            // A new quote
            if (isQuoteStopDateInPast) {
              this.newQuoteStopDatesInPastDialog = true
              this.saveForLater = saveForLater
              this.quoteClone = deepClone(quoteCloneForAPI)
              this.saveAndConvert = saveAndConvert
            } else {
              await this.createV2QuoteWrapper(saveForLater, quoteCloneForAPI, saveAndConvert)
            }
          }
          this.lockSaveButton = false
        }, 1000)
      })
    },
    getEmailPreference(saveForLater = false) {
      let emailPreference = 'email'
      if (saveForLater) {
        emailPreference = 'no-email'
      } else {
        if (this.quote.charterUpQuote) {
          emailPreference = 'charterup'
        }
      }
      return emailPreference
    },
    async createV2QuoteWrapper(saveForLater = false, quote, saveAndConvert) {
      const emailPreference = this.getEmailPreference(saveForLater)
      const params = {
          emailPreference,
          payload: quote,
        }
        try {
          this.lockSaveButton = true
          const savedQuoteResults = await this.createV2Quote(params)
          if (savedQuoteResults.status !== 200) {
            this.saveQuoteError = true
            this.saveQuoteErrorMessage =
              'There was an error saving this quote.'
            this.$store.dispatch('app/showAlert', {
              message: this.saveQuoteErrorMessage,
              type: 'error',
            })
            this.lockSaveButton = false
            return
          }

          const savedQuote = savedQuoteResults.data.quote

          if (saveAndConvert) {
            const approveQuotePayload = {
              meta: { billing: {} },
              isManual: true,
              payment_method: 'check',
            }
            const params = {
              quoteId: savedQuote.quoteId,
              payload: approveQuotePayload,
            }
            const approveQuoteResponse = await this.convert(params).catch(
              (e) => e.response
            )
            if (approveQuoteResponse.status !== 200) {
              const errorMessage = approveQuoteResponse?.data?.message
              return this.showAlert({
                type: 'error',
                message: `Error Approving Quote: ${errorMessage}`,
              })
            }
          }
          if (this.quote.contractId) {
            this.$router.push({
              name: 'contracts.view',
              params: { id: this.quote.contractId },
            })
          } else {
            if (this.showMarketplaceFeatures && !saveAndConvert) {
              await this.resetQuoteForm(true, false)
              this.$router.push({
                name: 'quotes.view',
                params: {
                  id: savedQuote.quoteId,
                  subRoute: 'bids',
                },
              })
            } else {
              this.$router.push({ name: 'quotes' })
            }
          }
          this.showAlert({
            message: `Quote ${savedQuote.quoteId} saved successfully.`,
            type: 'success',
            actionText: 'View Quote',
            actionPath: `/quotes/view/${savedQuote.quoteId}`,
            actionId: 'app-alert-button-action-quote-view',
          })
        } catch (e) {
          this.lockSaveButton = false
          this.showAlert({
            message: e.response?.data?.message || `Quote failed to save.`,
            type: 'error',
          })
          throw e
      }
    },
    openPDFInvoicePanel() {
      const component = () =>
        import('@/components/QuoteFormPDFInvoiceSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'PDF Invoice Estimate',
          quoteId: this.quote.quoteId,
        },
        component,
      })
    },
    hasVehicleConflict(tripIndex) {
      if (this.tripConflicts[tripIndex]) {
        return this.tripConflicts[tripIndex]?.hasConflict
      }
      return false
    },
    clearTripVehicleConflict(tripIndex) {
      if (this.tripConflicts[tripIndex]) {
        this.tripConflicts[tripIndex].hasConflict = false
      }
    },
    setLeadTemperatureType(leadTemperatureTypeId) {
      if (leadTemperatureTypeId) {
        this.quote.leadTemperatureTypeId = leadTemperatureTypeId
      }
    },
    setLeadFollowUpDate(leadFollowUpDate) {
      if (leadFollowUpDate) {
        this.quote.leadFollowUpDate = leadFollowUpDate
      }
    },
    setQuoteNoteText(value) {
      this.newQuoteNoteText = value
    },
    setQuoteNoteHtml(value) {
      this.newQuoteNoteHtml = value
    },
    scrollToFirstFormError() {
      let input = null
      let options = { offset: 30 }

      if (
        this.validationResults?.['local-leadsource-checkoutpage-form']
          ?.hasFailures
      ) {
        input = this.$refs['leadsource-checkoutpage-form']
        options.offset = 100
      } else if (
        this.validationResults?.['quote-form-configurations']?.hasFailures
      ) {
        input = this.$refs['quote-form-configurations']
        options.offset = 100
      } else if (this.validationResults?.['local-customer-form']?.hasFailures) {
        input = this.$refs['customer-form']
      } else if (
        this.validationResults?.['lead-follow-up-detail']?.hasFailures
      ) {
        input = this.$refs['quote-form-lead-follow-up']
        options.offset = 50
      } else if (
        this.validationResults?.['quote-form-enterprise-fields']?.hasFailures
      ) {
        input = this.$refs['quote-form-enterprise-fields']
        options.offset = 50
      } else if (
        this.validationResults?.['quote-form-classifications']?.hasFailures
      ) {
        input = this.$refs['quote-form-classifications']
        options.offset = 50
      } else {
        let detailsError = false
        let paymentError = false
        let indexOfFirstTripWithError = -1
        for (const [key, value] of Object.entries(this.validationResults)) {
          if (
            (key.includes('detail') || key.includes('market')) &&
            value.hasFailures
          ) {
            detailsError = true
            indexOfFirstTripWithError =
              indexOfFirstTripWithError < 0
                ? key.split('-')[0]
                : indexOfFirstTripWithError
          } else if (key.includes('payment') && value.hasFailures) {
            paymentError = true
            indexOfFirstTripWithError =
              indexOfFirstTripWithError < 0
                ? key.split('-')[0]
                : indexOfFirstTripWithError
          }
        }
        this.selectedTrip =
          indexOfFirstTripWithError >= 0
            ? indexOfFirstTripWithError
            : this.selectedTrip

        if (detailsError || paymentError) {
          input = this.$refs['trip-details-toggle']
          options.offset = 85
        }
      }

      if (input) {
        this.$vuetify.goTo(input.$el, options)
      }
    },
    toggleShowLastQuote(payload) {
      this.showLastQuote = !this.showLastQuote
      this.showPastQuotes = false
      if (payload) {
        this.lastQuoteId = payload
      }
    },
    async scrubQuoteFromAPI(quote, scrubRecurrenceIds = true) {
      if (quote.customer && quote.customer.customerId) {
        this.isCustomerSelected = true
        quote.customer.id = quote.customer.customerId
        if (quote.customer.customerAccount) {
          this.customerAccount = quote.customer.customerAccount
        }
      }
      if (this.isBroker || this.isSeniorISR || this.isMmeSales) {
        if (quote.leadSourceType) {
          quote.leadSource = quote.leadSourceType
          quote.leadSource.displayName = quote.leadSource.label
          quote.leadSource.leadSourceId = quote.leadSource.id
          this.leadSourceSearchTerm = quote.leadSource.label
        }
        if (quote.checkoutPage) {
          quote.checkoutPage.id = quote.checkoutPage.checkoutPageId
          this.checkoutPageSearchTerm = quote.checkoutPage.label
        } else {
          quote.checkoutPage = CHARTERUP_CHECKOUT_PAGE
        }
      }

      if (quote.expirationDate && quote.expirationTimezone) {
        quote.expirationDate = DateTime.fromISO(quote.expirationDate)
          .setZone(quote.expirationTimezone)
          .toISO({ includeOffset: false })
      }
      quote.trips = this.scrubTripsFromAPI(
        quote.trips,
        quote.charterUpQuote,
        scrubRecurrenceIds
      )
      return quote
    },
    scrubTripsFromAPI(
      trips,
      isMarketplaceQuote = false,
      scrubRecurrenceIds = true
    ) {
      trips.forEach((trip) => {
        trip.requiredVehicles = trip.vehicles
        trip.paymentType = { id: trip.paymentTypeId }
        trip.stops.sort((a, b) => (a.orderIndex > b.orderIndex ? 0 : -1))
        delete trip.tripVehicleGroups
        delete trip.tripAddresses
        for (const stop of trip.stops) {
          if (stop.address) {
            stop.address.addressName = stop.address.name
          }

          if (stop.pickupDatetime) {
            let pickupTime = DateTime.fromISO(stop.pickupDatetime, {
              zone: stop.address.timeZone,
            })
              .toISO({ suppressMilliseconds: true })
              .split('T')[1]
            const pickupTimeParts = pickupTime.split(':')
            pickupTime = `${pickupTimeParts[0]}:${pickupTimeParts[1]}`

            stop.pickupDate = DateTime.fromISO(stop.pickupDatetime, {
              zone: stop.address.timeZone,
            }).toISODate()
            stop.pickupTime = pickupTime
          }

          if (stop.spotTime?.spotTime) {
            stop.spotTime.spotTime = DateTime.fromISO(stop.spotTime.spotTime, {
              zone: stop.address.timeZone,
            }).toISO({ includeOffset: false })
          }

          if (stop.dropoffDatetime) {
            let dropoffTime = DateTime.fromISO(stop.dropoffDatetime, {
              zone: stop.address.timeZone,
            })
              .toISO({ suppressMilliseconds: true })
              .split('T')[1]
            const dropoffTimeParts = dropoffTime.split(':')
            dropoffTime = `${dropoffTimeParts[0]}:${dropoffTimeParts[1]}`

            stop.dropoffDate = DateTime.fromISO(stop.dropoffDatetime, {
              zone: stop.address.timeZone,
            }).toISODate()
            stop.dropoffTime = dropoffTime
          }
        }
        for (const recurrence of trip.recurrences) {
          if (scrubRecurrenceIds) {
            recurrence.recurrenceId = null
          }
          recurrence.recurrenceExclusions.forEach((recurrenceExclusion) => {
            recurrenceExclusion.recurrenceExclusionId = null
          })
        }

        for (const method of trip.paymentMethods) {
          if (typeof method.isAllowed === 'number') {
            method.isAllowed = method.isAllowed === 1
          }
        }
        for (const method of trip?.stagePaymentMethods
          ?.checkoutPaymentMethods || []) {
          if (typeof method.isAllowed === 'number') {
            method.isAllowed = method.isAllowed === 1
          }
        }
        for (const method of trip?.stagePaymentMethods?.balancePaymentMethods ||
          []) {
          if (typeof method.isAllowed === 'number') {
            method.isAllowed = method.isAllowed === 1
          }
        }

        const charges = trip.charges || []
        const processingFeeCharge = charges.find(
          (c) =>
            c?.chargeType?.key === 'processing_fees' || c?.chargeType?.id === 4
        )
        const processingFeeChargeAmount = processingFeeCharge
          ? processingFeeCharge.amount
          : 0

        const marketplaceDiscountCharge = isMarketplaceQuote
          ? charges.find(
              (charge) =>
                charge?.chargeType?.key === 'discount' ||
                charge?.chargeType?.id === 15
            )
          : null

        const marketplaceMarkupCharge = isMarketplaceQuote
          ? charges.find(
              (charge) =>
                charge?.chargeType?.key === 'markup' ||
                charge?.chargeType?.id === 18
            )
          : null

        const marketplaceDiscountChargeAmount = marketplaceDiscountCharge
          ? marketplaceDiscountCharge.amount
          : 0

        const marketplaceMarkupChargeAmount = marketplaceMarkupCharge
          ? marketplaceMarkupCharge.amount
          : 0

        const subtotal =
          trip.total -
          processingFeeChargeAmount -
          marketplaceDiscountChargeAmount -
          marketplaceMarkupChargeAmount

        if (processingFeeCharge) {
          trip.originalProcessingFeeCharge = processingFeeCharge
          trip.processingFeeCharge = processingFeeChargeAmount
          trip.processingFeePercentage = Math.round(
            (parseFloat(processingFeeChargeAmount) / parseFloat(subtotal)) *
              100.0
          )
          trip.charges = trip.charges.filter(
            (c) =>
              c?.chargeType?.key !== 'processing_fees' ||
              c?.chargeType?.id !== 4
          )
        } else {
          trip.processingFeePercentage = 0
        }
        if (marketplaceDiscountCharge) {
          trip.originalMarketplaceDiscountCharge = marketplaceDiscountCharge
          trip.marketplaceDiscountCharge = marketplaceDiscountChargeAmount
          trip.marketplaceDiscountPercent = Math.round(
            (marketplaceDiscountChargeAmount /
              (subtotal + processingFeeChargeAmount)) *
              -100
          )
          trip.charges = trip.charges.filter(
            (c) => c?.chargeType?.key !== 'discount' || c?.chargeType?.id !== 15
          )
        } else {
          trip.marketplaceDiscountPercent = 0
        }
        if (marketplaceMarkupCharge) {
          trip.originalMarketplaceAmountCharge = marketplaceMarkupCharge
          trip.marketplaceMarkupCharge = marketplaceMarkupChargeAmount
          trip.marketplaceMarkupPercent = Math.round(
            (marketplaceMarkupChargeAmount /
              (subtotal + processingFeeChargeAmount)) *
              100
          )
          trip.charges = trip.charges.filter(
            (c) => c?.chargeType?.key !== 'markup' || c?.chargeType?.id !== 18
          )
        } else {
          trip.marketplaceMarkupPercent = 0
        }
        if (trip.dueDate) {
          trip.dueDate = DateTime.fromISO(trip.dueDate, {
            zone: 'utc',
          }).toISODate()
        }
        if (trip.tripContact) {
          trip.istripContactSelected = true
        } else {
          trip.tripContact = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
          }
          trip.istripContactSelected = false
        }
      })
      return trips
    },
    async searchLeadSourceList(value) {
      if (typeof value !== 'string' || value.length === 0) {
        return
      }

      const filterObject = {
        column: {
          _t_id: 'lead_source_search_id',
          prop: 'displayName',
          filterType: 'contains',
          filterAsIs: true,
        },
        value,
      }
      const leadSourceFilter = filter()
      const parentFilter = leadSourceFilter.createParent('and')
      leadSourceFilter.add(parentFilter, filterObject)
      if (this.leadSourceDebounce) {
        clearTimeout(this.leadSourceDebounce)
      }
      this.leadSourceDebounce = setTimeout(async () => {
        const params = {
          filters: leadSourceFilter.asQueryParams(),
          pageSize: 5,
        }
        const matchedLeadSources = await leadSources.getLeadSources(params)
        const suggestedLeadSources = matchedLeadSources?.data?.resultList || []
        this.suggestedLeadSources = suggestedLeadSources.filter(
          (suggestedLeadSource) => suggestedLeadSource.internalName
        )
      }, 500)
    },
    selectLeadSourceFromList(leadSource) {
      if (!leadSource) {
        return
      }
      if (!leadSource.internalName) {
        return false
      }
      const { company } = this.currentUser
      this.quote.leadSource.id = leadSource.leadSourceId
      this.quote.leadSource.label = leadSource.displayName
      this.quote.leadSource.key = leadSource.internalName
      this.quote.leadSource.partnerCompanyId = leadSource.partnerCompanyId
      this.quote.leadSource.company = { id: company.companyId }
      this.isLeadSourceSelected = true
    },
    selectContractFromList(contract) {
      this.contract = contract
    },
    async searchCheckoutPagesList(value) {
      const filterObject = {
        column: {
          _t_id: 'checkout_pages_search_id',
          prop: 'name',
          filterType: 'contains',
        },
      }
      filterObject.value = value
      if (typeof value !== 'string') {
        return
      }
      const checkoutPageFilter = filter()
      const parentFilter = checkoutPageFilter.createParent('and')
      checkoutPageFilter.add(parentFilter, filterObject)
      if (this.checkoutPageDebounce) {
        clearTimeout(this.checkoutPageDebounce)
      }
      this.checkoutPageDebounce = setTimeout(async () => {
        const params = {
          filters: checkoutPageFilter.asQueryParams(),
          pageSize: 5,
        }
        const matchedCheckoutPages = await checkoutPages.getCheckoutPages(
          params
        )
        this.suggestedCheckoutPages = matchedCheckoutPages?.data?.resultList
          ?.length
          ? matchedCheckoutPages.data.resultList
          : [CHARTERUP_CHECKOUT_PAGE]
      }, 500)
    },
    async searchContractsList(value) {
      const filterObject = {
        column: {
          _t_id: 'checkout_pages_search_id',
          prop: 'contractName',
          filterType: 'contains',
        },
      }
      filterObject.value = value
      if (typeof value === 'string') {
        if (value.length === 0) {
          return
        }
      } else {
        return
      }
      const contractsFilter = filter()
      const parentFilter = contractsFilter.createParent('and')
      contractsFilter.add(parentFilter, filterObject)
      const params = {
        filters: contractsFilter.asQueryParams(),
        pageSize: 5,
      }
      const matchedContractQuotes = await this.$store
        .dispatch('quotes/quotesListView', params)
        .catch((error) => {
          logger.error(error)
          this.tableProps.loading = false

          return false
        })
      this.suggestedContracts = [].concat(
        matchedContractQuotes?.data?.resultList || []
      )
    },

    selectCheckoutPageFromList(checkoutPage) {
      if (!checkoutPage) {
        return
      }

      this.quote.checkoutPage.id = checkoutPage.companyCheckoutPageId
      this.quote.checkoutPage.label = checkoutPage.name
      this.quote.checkoutPage.key = checkoutPage.internalName

      this.searchCheckoutPagesList('')
      this.isCheckoutPageSelected = true
      this.getPricing()
    },
    resetLeadSource() {
      this.quote.leadSource.id = null
      this.quote.leadSource.label = null
      this.quote.leadSource.key = null
      this.isLeadSourceSelected = false
    },
    resetCheckoutPage() {
      this.quote.checkoutPage.id = null
      this.quote.checkoutPage.label = null
      this.quote.checkoutPage.key = null
      this.isCheckoutPageSelected = false

      this.quote.pricingMethod = null

      this.searchCheckoutPagesList('')
    },
    async singleTripTotal(trip) {
      const { charges = [], processingFeePercentage } = trip
      const tripRecurrences = trip.recurrences
      const recurrenceCount =
        (await tripRecurrences.reduce(async (count, recurrence) => {
          const {
            data: { recurrenceEventDateList },
          } = await recurrences.getRecurrenceEventDateList([recurrence])
          count += recurrenceEventDateList.length
          return count
        }, 0)) + 1

      const chargesTotal = charges.reduce(
        (chargeTotal, charge) => chargeTotal + parseFloat(charge.amount || 0),
        0
      )

      const tripProcessingFee = (chargesTotal * processingFeePercentage) / 100
      const tripMarkupAmount =
        (chargesTotal * trip.marketplaceMarkupPercent || 0) / 100
      const marketplaceDiscountMultiplier =
        1 - trip.marketplaceDiscountPercent / 100
      const tripTotal =
        (chargesTotal + parseFloat(tripProcessingFee) + tripMarkupAmount) *
        marketplaceDiscountMultiplier

      const recurringTripTotal = tripTotal * recurrenceCount
      return { tripTotal, recurringTripTotal }
    },
    captureValidationResults(validationResults) {
      const { tripIndex, tripModule, hasFailures = false } = validationResults
      this.validationResults[tripIndex] = { hasFailures }
      this.validationResults[`${tripIndex}-${tripModule}`] = { hasFailures }
      this.$forceUpdate()
    },
    subTabClass(tripIndex, tripModule, subTab) {
      const validationResults = this.validationResults
      if (
        (this.isModeAdd || this.isModeEdit) &&
        this.formSubmitted &&
        validationResults[`${tripIndex}-${tripModule}`] &&
        validationResults[`${tripIndex}-${tripModule}`].hasFailures === true
      ) {
        if (this.toggleSubTabs === subTab) {
          return 'error'
        }
        return 'error-non-selected'
      }
      if (
        (this.isModeAdd || this.isModeEdit) &&
        this.formSubmitted &&
        validationResults[`${tripIndex}-${tripModule}-method`] &&
        validationResults[`${tripIndex}-${tripModule}-method`].hasFailures ===
          true
      ) {
        if (this.toggleSubTabs === subTab) {
          return 'error'
        }
        return 'error-non-selected'
      }
      if (this.toggleSubTabs === subTab) {
        return 'primary'
      }
      return ''
    },
    async handlePreBookingToggle() {
      if (this.quote.isPreBooking) {
        await this.setCharterUpAsLeadSource()

        this.suggestedCheckoutPages.push(CHARTERUP_CHECKOUT_PAGE)

        this.selectCheckoutPageFromList(CHARTERUP_CHECKOUT_PAGE)
      }
    },
    scrubQuote(quoteClone, addTripId = false) {
      if (!quoteClone) {
        quoteClone = deepClone(this.quote)
      }
      const { customer, company, trips = [] } = quoteClone
      const phone = customer?.phone || ''
      customer.phone = phone.replace(/[^0-9]/g, '')
      // To link the data correctly certain fields must be filled in
      trips.forEach((trip, tripIndex) => {
        if (addTripId) {
          trip.tripId = tripIndex
        }
        trip.customer = customer
        trip.company = company
        if (trip.dueDate) {
          trip.dueDate = DateTime.fromFormat(trip.dueDate, 'yyyy-MM-dd', {
            zone: 'America/New_York',
          })
            .toUTC()
            .toISO()
        }
        const { tripType = {}, requiredVehicles = [] } = trip
        const foundTripType = this.tripTypes.find((t) => t.id === tripType?.id)
        if (foundTripType) {
          trip.tripTypeKey = foundTripType.key
        }
        requiredVehicles.forEach((requiredVehicle) => {
          const { vehicleType = {} } = requiredVehicle
          const foundVehicleType = this.vehicleTypes.find(
            (v) => v.id === vehicleType?.id
          )
          if (foundVehicleType) {
            requiredVehicle.vehicleTypeKey = foundVehicleType.key
          }
        })
        const { stops = [], charges = [], processingFeeCharge = 0 } = trip
        const marketplaceDiscountCharge = trip?.marketplaceDiscountCharge || 0
        const marketplaceMarkupCharge = trip?.marketplaceMarkupCharge || 0
        if (processingFeeCharge > 0) {
          if (trip.originalProcessingFeeCharge) {
            trip.originalProcessingFeeCharge.amount = processingFeeCharge
            charges.push(trip.originalProcessingFeeCharge)
          } else {
            charges.push({
              amount: processingFeeCharge,
              chargeType: {
                id: 4,
                key: 'processing_fees',
                label: 'Processing Fees',
              },
            })
          }
        }
        if (marketplaceMarkupCharge > 0) {
          if (trip.originalMarketplaceAmountCharge) {
            trip.originalMarketplaceAmountCharge.amount = marketplaceMarkupCharge
            charges.push(trip.originalMarketplaceAmountCharge)
          } else {
            charges.push({
              amount: marketplaceMarkupCharge,
              chargeType: {
                id: 18,
                key: 'markup',
                label: 'Markup',
              },
            })
          }
        }
        if (marketplaceDiscountCharge * -1 > 0) {
          if (trip.originalMarketplaceDiscountCharge) {
            trip.originalMarketplaceDiscountCharge.amount = marketplaceDiscountCharge
            charges.push(trip.originalMarketplaceDiscountCharge)
          } else {
            charges.push({
              amount: marketplaceDiscountCharge,
              chargeType: {
                id: 15,
                key: 'discount',
                label: 'Discount',
              },
            })
          }
        }
        stops.forEach((stop, stopIndex) => {
          stop.orderIndex = stopIndex
          const timeZone = stop?.address?.timeZone
          if (stop.pickupDate) {
            stop.pickupDatetime = getDatetimeFromDateAndTimeStrings(
              stop.pickupDate,
              stop.pickupTime,
              timeZone
            )
          }
          if (stop.dropoffDate) {
            stop.dropoffDatetime = getDatetimeFromDateAndTimeStrings(
              stop.dropoffDate,
              stop.dropoffTime,
              timeZone
            )
          }
        })
        trip.startDate = stops?.[0]?.pickupDatetime
      })
      if (quoteClone.leadFollowUpDate) {
        quoteClone.leadFollowUpDate = DateTime.fromISO(
          quoteClone.leadFollowUpDate
        )
          .setZone(this.currentUser?.timeZone || 'America/New_York')
          .toISO()
      }
    },
    duplicateTrips(trips, marketplace = false) {
      const clonedTrips = deepClone(trips)
      this.scrubTripsFromAPI(clonedTrips, marketplace)
      clonedTrips.forEach(this.duplicateTrip)
      this.quote.trips = this.trips = clonedTrips
      this.showPastQuotes = false
      this.showLastQuote = false
      this.$forceUpdate()
      this.$nextTick(() => {
        this.distanceUpdateKey = v4()
        this.getPricing()
      })
    },
    duplicateTripFromPrevious(trip, tripIndex) {
      const clonedTrip = deepClone(trip)
      clonedTrip.isManuallySelectedPricingMarket = false
      this.duplicateTrip(clonedTrip, tripIndex)
      this.quote.trips.push(clonedTrip)
      this.trips = this.quote.trips
      this.$forceUpdate()
      this.$nextTick(() => {
        this.distanceUpdateKey = v4()
        this.getPricing()
      })
    },
    duplicateTrip(trip, tripIndex) {
      delete trip.id
      delete trip.tripId
      delete trip.quotes
      delete trip.createdOn
      delete trip.reservations
      delete trip.active
      trip.isManuallySelectedPricingMarket = false
      trip.tripAmenities = []
      trip.charges = trip.charges.filter(
        (charge) => charge.chargeType.id !== ChargeTypeId.Amenities
      )
      trip.openForBid = false
      trip.calendarDays = 1
      const { charges = [], stops = [], rates = [], paymentMethods = [] } = trip
      let { vehicles = [], requiredVehicles = [] } = trip
      if (this.quote.isMarketplaceQuote && trip.vehicleNeededEntireTrip) {
        this.setVehicleNeededForEntireTrip({
          value: trip.vehicleNeededEntireTrip,
          tripIndex: tripIndex,
        })
      }
      if (
        (!vehicles || vehicles.length === 0) &&
        requiredVehicles &&
        requiredVehicles.length > 0
      ) {
        vehicles = requiredVehicles
      }
      const extractTimeAndDate = (dateTime, timeZone) => {
        const hours = DateTime.fromISO(dateTime, { zone: timeZone }).toFormat(
          'HH'
        )
        const minutes = DateTime.fromISO(dateTime, { zone: timeZone }).toFormat(
          'mm'
        )
        const time = `${hours}:${minutes}`

        const date = DateTime.fromISO(dateTime, { zone: timeZone }).toISODate()
        return { date, time }
      }
      if (trip.dueDate) {
        trip.dueDate = DateTime.fromISO(trip.dueDate).toISODate()
      }
      trip.paymentType = {
        id: trip.paymentTypeId || trip?.paymentType?.id,
      }

      const tripNotes = (trip.tripNotes || []).map((note) => {
        delete note.noteId
        return note
      })
      trip.tripNotes = tripNotes

      stops.forEach((stop) => {
        delete stop.stopId
        delete stop.id
        delete stop.trip
        delete stop.createdOn
        stop.isPickupEstimated = false
        stop.isDropoffEstimated = false
        const { address } = stop
        const { timeZone } = address || {}
        if (stop.pickupDatetime) {
          const { date, time } = extractTimeAndDate(
            stop.pickupDatetime,
            timeZone
          )
          stop.pickupDate = date
          stop.pickupTime = time
        }
        if (stop.dropoffDatetime) {
          const { date, time } = extractTimeAndDate(
            stop.dropoffDatetime,
            timeZone
          )
          stop.dropoffDate = date
          stop.dropoffTime = time
        }
        if (stop.address && (stop.address.id || stop.address.addressId)) {
          delete stop.address.id
          delete stop.address.addressId
          stop.address.addressName = stop.address.name
        }
        if (stop?.flightInformation?.flightInformationId) {
          delete stop.flightInformation.flightInformationId
        }
      })
      const clearId = (list, idProp) => {
        const defaultId = 'id'
        list.forEach((item) => {
          delete item[idProp || defaultId]
        })
      }

      if (trip.originalProcessingFeeCharge) {
        delete trip.originalProcessingFeeCharge.tripChargeId
        delete trip.originalProcessingFeeCharge.tripId
      }
      if (trip.originalMarketplaceDiscountCharge) {
        delete trip.originalMarketplaceDiscountCharge.tripChargeId
        delete trip.originalMarketplaceDiscountCharge.tripId
      }

      clearId(rates, 'tripRateId')
      clearId(charges, 'tripChargeId')
      clearId(vehicles, 'tripVehicleId')
      clearId(paymentMethods, 'tripPaymentMethodId')

      trip.requiredVehicles = vehicles
      return trip
    },
    getTimeZoneForTrip(trip) {
      return trip?.stops[0]?.address?.timeZone
    },
    setDueDateKey() {
      this.$nextTick(() => {
        this.dueDateKey = v4()
      })
    },
    async changeCurrencyType(currencyType) {
      if (currencyType !== 'USD') {
        this.showAlternativeCurrency = true
        const exchangeRateData = await exchangeRate
          .getExchangeRate()
          .catch((e) => e)
        this.exchangeRate = exchangeRateData?.data?.rates?.[currencyType]
      }
    },
    showDialog(type) {
      if (type === 'approveQuote' && this.isBASQuote) {
        type = 'approveBASQuote'
      }
      this.dialogType = type

      if (type === 'approveQuote') {
        return
      }

      this.actionsDialog = true
    },
    onCloseDialog() {
      this.actionsDialog = false
      this.sidebarDialog = false
    },
    quoteApproved() {
      this.actionsDialog = false
      if (this.quoteId) {
        this.$router.push({
          name: 'quotes.view',
          params: {
            id: this.quoteId,
          },
        })
        this.$forceUpdate()
      } else if (this.quote.contractId) {
        this.$router.push({
          name: 'contracts.view',
          params: { id: this.quote.contractId },
        })
      } else {
        this.$router.push({ name: 'quotes' })
      }
    },
    async copyMarketplaceLink() {
      try {
        const linkResponse = await this.getMarketplaceLink(this.quoteId)
        const link = linkResponse.data.link
        navigator.clipboard.writeText(link).then(
          () => {
            this.$store.dispatch('app/showAlert', {
              type: 'success',
              message: 'Marketplace link copied to your clipboard',
            })
          },
          () => {
            this.$store.dispatch('app/showAlert', {
              type: 'error',
              message: 'Marketplace link could not be copied to your clipboard',
            })
          }
        )
      } catch (e) {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'There was an error getting the marketplace link',
        })
      }
    },
    async approveBASQuote() {
      this.actionsDialog = false
      const approveQuotePayload = {
        meta: { billing: {} },
        manual: true,
        payment_method: 'check',
      }
      const params = {
        quoteId: this.quoteId,
        payload: approveQuotePayload,
      }
      const approveQuoteResponse = await this.convert(params).catch(
        (e) => e.response
      )
      if (approveQuoteResponse.status !== 200) {
        const errorMessage = approveQuoteResponse?.data?.message
        return this.showAlert({
          type: 'error',
          message: `Error Approving Quote: ${errorMessage}`,
        })
      } else {
        if (this.quoteId) {
          this.$router.push({
            name: 'quotes.view',
            params: {
              id: this.quoteId,
            },
          })
        } else if (this.quote.contractId) {
          this.$router.push({
            name: 'contracts.view',
            params: { id: this.quote.contractId },
          })
        } else {
          this.$router.push({ name: 'quotes' })
        }
        this.showAlert({
          message: 'Successfully approved quote',
        })
      }
    },
    checkoutPageUrl() {
      const { checkoutPage = {}, hash } = this.quote || {}
      if (
        !checkoutPage ||
        checkoutPage?.checkoutPageId === CHARTERUP_CHECKOUT_PAGE.checkoutPageId
      ) {
        return `https://${environmentPrefix()}.charterup.com/guest-checkout/${hash}`
      }
      const domain = checkoutPage?.domain || {}
      return `${domain}/checkout/${hash}`
    },
    viewCheckoutPage() {
      window.open(this.checkoutPageUrl(), '_blank')
    },
    pdfUrl() {
      const pdfHost = baseUrl('pdf')
      const { updatedOn, hash } = this.quote
      const timestamp = new Date(updatedOn).getTime()
      return `https://${pdfHost}/pdf/quote-${hash}-${timestamp}.pdf`
    },
    viewPDF() {
      window.open(this.pdfUrl(), '_blank')
    },
    copyPDFUrl() {
      navigator.clipboard.writeText(this.pdfUrl())
      this.showAlert({
        message: 'PDF url copied to clipboard.',
      })
    },
    editMode() {
      this.$router.push({
        name: 'quotes.edit',
        params: { id: this.quote.quoteId },
      })
      this.getPricing()
    },
    formatDateTimeForDisplay(dateTime) {
      const date = DateTime.fromISO(dateTime).toLocaleString(
        DateTime.DATE_SHORT
      )
      const time = DateTime.fromISO(dateTime).toLocaleString(
        DateTime.TIME_SIMPLE
      )
      return `${date} ${time}`
    },
    changeActiveTripIndex(tripIndex) {
      if (this.activeTripIndex !== tripIndex) {
        this.activeTripIndex = tripIndex
      }
    },
    changeTripName(trip, tripName) {
      trip.routeName = tripName
    },
    clearTripName(trip) {
      trip.routeName = null
    },
    changeClassification(typeKey, classificationId) {
      if (typeKey === 'product') {
        this.quote.productClassificationId = classificationId
      } else if (typeKey === 'sourcing-team') {
        this.quote.sourcingTeamClassificationId = classificationId
      } else if (typeKey === 'support-team') {
        this.quote.supportTeamClassificationId = classificationId
      }
    },
    async populateContractDetails(contractId) {
      const contractResponse = await contracts
        .getContract(contractId)
        .catch((error) => {
          logger.error(error)
        })
      this.suggestedContracts.push(contractResponse.data)
      this.contract = contractResponse.data

      const leadSource = this.contract.leadSourceType
      let leadSourceForList = {
        leadSourceId: leadSource.id,
        displayName: leadSource.label,
        internalName: leadSource.key,
        partnerCompanyId: leadSource.partnerCompanyId,
      }
      this.suggestedLeadSources.push(leadSourceForList)
      this.selectLeadSourceFromList(leadSourceForList)

      let checkoutPage
      if (this.contract.checkoutPage) {
        checkoutPage = {
          companyCheckoutPageId: this.contract.checkoutPage.checkoutPageId,
          name: this.contract.checkoutPage.label,
          internalName: this.contract.checkoutPage.key,
        }
      } else {
        checkoutPage = CHARTERUP_CHECKOUT_PAGE
      }
      this.suggestedCheckoutPages.push(checkoutPage)
      this.selectCheckoutPageFromList(checkoutPage)
      this.disableContractDetailFields = true
    },
    linkToContract() {
      this.$store.dispatch('app/openDialog', {
        component: () =>
          import('@/components/QuoteFormFooterLinkToContract.vue'),
        data: {
          quoteId: this.quote.quoteId,
          existingContract: this.contract,
        },
      })
    },
    updateMarketplace() {
      const component = () => import('@/components/QuoteMarketplaceSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'Update Marketplace',
          quote: this.quote,
        },
        component,
      })
    },
    updateSentBy() {
      const component = () => import('@/components/QuoteCreatedBySidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'Update Sent By User',
          quoteId: this.quote.quoteId,
        },
        component,
      })
    },
    updateLeadTempAndFollowUpDate() {
      const component = () =>
        import('@/components/QuoteFormCRMActionsSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'CRM Actions',
          quoteId: this.quote.quoteId,
        },
        component,
      })
    },
    async initializeSplitTrip() {
      this.confirmSplitTripLoading = true
      this.setShouldSplitTrip(false)
      let quoteClone = deepClone(this.quote)
      this.scrubQuote(quoteClone)
      quoteClone = scrubQuoteForAPI(
        quoteClone,
        true,
        this.isCapturePaymentStagesEnabled
      )
      const splitTripQuote = await this.getSplitTrips(quoteClone).catch(
        () => false
      )
      if (Array.isArray(splitTripQuote?.data?.trips)) {
        this.duplicateTrips(splitTripQuote?.data?.trips, true)
      }
      this.quote.trips.forEach((trip) => (trip.refreshPricing = true))
      this.confirmSplitTripLoading = false
      this.confirmSplitTripDialog = false
    },
    setVehicleNeededForEntireTrip(data) {
      this.quote.busNeededForEntireTrip = data.value
      this.quote.trips[data.tripIndex].vehicleNeededEntireTrip = data.value
    },
    getEarliestPickupDateTime() {
      let earliestPickup = null
      for (const trip of this.quote.trips) {
        const stop = trip.stops[0]
        const pickupDatetime = getDatetimeFromDateAndTimeStrings(
          stop.pickupDate,
          stop.pickupTime,
          stop?.address?.timeZone
        )
        if (!earliestPickup || pickupDatetime < earliestPickup) {
          earliestPickup = pickupDatetime
        }
      }
      return DateTime.fromISO(earliestPickup)
    },
    autoSetExpirationDateTime() {
      if (!this.isQuoteExpirationAutosetEnabled) {
        return
      }

      this.quote.isExpirationDateTimeAutoset = true

      const earliestPickup = this.getEarliestPickupDateTime()
      if (!earliestPickup) {
        return
      }

      let creationDate = this.quote.createdOn
        ? DateTime.fromISO(this.quote.createdOn)
        : DateTime.fromISO(DateTime.local())
      let dayDifference = earliestPickup.diff(creationDate, 'days').days
      let hourDifference = earliestPickup.diff(creationDate, 'hours').hours
      let expirationDate

      if (dayDifference > 30) {
        expirationDate = creationDate
          .plus({ days: 14 })
          .set({ hour: 12, minutes: 0, seconds: 0 })
      } else if (dayDifference >= 15 && dayDifference <= 30) {
        expirationDate = creationDate
          .plus({ days: 7 })
          .set({ hour: 12, minutes: 0, seconds: 0 })
      } else if (dayDifference >= 10 && dayDifference < 15) {
        expirationDate = creationDate
          .plus({ days: 4 })
          .set({ hour: 12, minutes: 0, seconds: 0 })
      } else if (hourDifference >= 48 && dayDifference < 10) {
        expirationDate = creationDate
          .plus({ hours: 49 })
          .set({ minutes: 0, seconds: 0 })
      } else if (hourDifference >= 12 && hourDifference < 48) {
        expirationDate = creationDate
          .plus({ hours: 6 })
          .set({ minutes: 0, seconds: 0 })
      } else if (hourDifference < 12) {
        if (creationDate.minute < 30) {
          expirationDate = creationDate
            .plus({ hours: 1 })
            .set({ minutes: 30, seconds: 0 })
        } else {
          expirationDate = creationDate
            .plus({ hours: 2 })
            .set({ minutes: 0, seconds: 0 })
        }
      }
      if (expirationDate) {
        this.quote.expirationTimezone = DateTime.local().zoneName
        this.quote.expirationDate = expirationDate.toISO()
      }
    },
    setDefaultValues() {
      if (this.isModeAdd) {
        if (this.isRA || this.isRAManager) {
          this.quote.checkoutPage = CHARTERUP_CHECKOUT_PAGE
          this.quote.pricingMethod = 'bids'
          return
        }
      }
    },
    setCustomerAccountDefaultValues() {
      if (!this.customerAccountDefaults) {
        return
      }
      if (this.customerAccountDefaults.pricingMethodKey) {
        const existingPricingMethod = this.availablePricingMethods.find(
          (pm) => pm.key === this.customerAccountDefaults.pricingMethodKey
        )
        if (existingPricingMethod) {
          this.quote.pricingMethod = this.customerAccountDefaults.pricingMethodKey
        }
      }
      if (this.customerAccountDefaults.productClassification?.id) {
        this.quote.productClassificationId = this.customerAccountDefaults.productClassification.id
      }
      if (this.customerAccountDefaults.sourcingTeamClassification?.id) {
        this.quote.sourcingTeamClassificationId = this.customerAccountDefaults.sourcingTeamClassification.id
      }
      if (this.customerAccountDefaults.supportTeamClassification?.id) {
        this.quote.supportTeamClassificationId = this.customerAccountDefaults.supportTeamClassification.id
      }
      if (this.customerAccountDefaults?.minQuality !== undefined) {
        this.quote.minQuality = this.customerAccountDefaults.minQuality
      }
      if (this.customerAccountDefaults.accountExecutive?.id) {
        this.quote.accountExecutiveId = this.customerAccountDefaults.accountExecutive.id
      }

      const existingPaymentTypeId = this.paymentTypes?.find(
        (type) => type.key === this.customerAccountDefaults.paymentType?.key
      )?.id

      for (const trip of this.quote?.trips || []) {
        const existingMethod = trip?.paymentMethods?.find(
          (method) =>
            method?.paymentMethodType?.key ===
            this.customerAccountDefaults.paymentMethodType?.key
        )
        if (existingMethod) {
          for (const method of trip?.paymentMethods || []) {
            method.isAllowed = false
          }
          existingMethod.isAllowed = true
          for (const method of trip?.stagePaymentMethods
            ?.checkoutPaymentMethods || []) {
            method.isAllowed =
              method.paymentMethodId === existingMethod.paymentMethodTypeId
          }
          for (const method of trip?.stagePaymentMethods
            ?.balancePaymentMethods || []) {
            method.isAllowed =
              method.paymentMethodId === existingMethod.paymentMethodTypeId
          }
        }

        if (
          this.customerAccountDefaults.discountPercent &&
          this.quote.pricingMethod !== 'single_price'
        ) {
          trip.marketplaceDiscountPercent = this.customerAccountDefaults.discountPercent
        }
        if (
          this.customerAccountDefaults.markupPercent &&
          this.quote.pricingMethod !== 'single_price'
        ) {
          trip.marketplaceMarkupPercent = this.customerAccountDefaults.markupPercent
        }
        if (existingPaymentTypeId) {
          trip.paymentType.id = existingPaymentTypeId
          trip.paymentTypeId = existingPaymentTypeId
        }
        if (
          this.customerAccountDefaults.downPaymentPercent != null &&
          this.customerAccountDefaults.downPaymentPercent >= 0 &&
          this.customerAccountDefaults.downPaymentPercent <= 100
        ) {
          trip.depositPercentage = this.customerAccountDefaults.downPaymentPercent
        }
        if (this.customerAccountDefaults.paymentTermsDays >= 0) {
          if (this.earliestPickupDateTime(trip) !== null) {
            const dueDate = this.earliestPickupDateTime(trip).plus({
              days: this.customerAccountDefaults.paymentTermsDays,
            })
            trip.dueDate = dueDate.toISODate()
          }
        }
      }
    },
    earliestPickupDateTime(trip) {
      const stopToPickupTime = function (stop) {
        const pickupDate = stop.pickupDate
        const pickupTime = stop.pickupTime
        const timeZone = stop.address.timeZone

        return DateTime.fromISO(`${pickupDate}T${pickupTime}`, {
          zone: timeZone,
        })
      }
      const pickupDateTimes = trip.stops
        .filter((s) => !!s.pickupDate)
        .map((s) => stopToPickupTime(s))
      let earliestPickupDateTime = null
      for (const pickupDateTime of pickupDateTimes) {
        if (
          earliestPickupDateTime === null ||
          pickupDateTime.toMillis() < earliestPickupDateTime.toMillis()
        ) {
          earliestPickupDateTime = pickupDateTime
        }
      }
      return earliestPickupDateTime
    },
    setQuoteTier(tierId, isAutoSelected) {
      const tier = this.tiers.find((tier) => tier.tierId === tierId)
      if (tier) {
        this.quote.tierId = tierId
        this.quote.tier = deepClone(tier)
        this.isQuoteTierAutoSelected = isAutoSelected
      }
    },
    handlePaymentMethodSelection(tripIndex, paymentStageKey, paymentMethodTypeId, isAllowed) {
      if (paymentStageKey === PaymentStageKey.Checkout) {
        this.quote.trips?.[tripIndex]?.stagePaymentMethods?.checkoutPaymentMethods?.forEach(
          (method) => {
            if (method.paymentMethodId === paymentMethodTypeId) {
              method.isAllowed = isAllowed
            }
          }
        )
      }
      if (paymentStageKey === PaymentStageKey.RemainingBalance) {
        this.quote.trips?.[tripIndex]?.stagePaymentMethods?.balancePaymentMethods?.forEach(
          (method) => {
            method.isAllowed = (method.paymentMethodId === paymentMethodTypeId) && isAllowed
          }
        )
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.account-executive-autocomplete ::v-deep .v-input__control {
  min-height: 44px;
}
.lead-source-button {
  margin: 0;
  align-self: end;
  color: $primary;
  cursor: pointer;
}

.why {
  margin-top: -21px;
}

.not {
  margin-top: -25px;

  h1 {
    margin-left: 33px;
  }
  // max-width: 870px;
}

.deleted-warning {
  color: $red;
  font-size: 14px;
  padding: 10px;
  border: 1px solid $red;
  border-radius: 5px;
  background-color: $error-pale;
  margin-top: 18px;
}

@media only screen and (max-width: 1140px) {
  .why {
    margin-top: -25px;

    h1 {
      margin-left: 33px;
    }
  }
}

.market-rates-button {
  position: fixed;
  top: 80px;
  right: 12px;
  z-index: 5;
  width: 52px;
  height: 52px;
  background-color: $white;
  border: 1px solid $blue;
  border-radius: 26px;
}

.quote-trip-tab {
  position: relative;

  &::v-deep .v-tabs__item {
    padding-right: 10px;
    padding-left: 10px;
    &:not(.v-tabs__item--active) {
      .icon-button-close {
        margin-bottom: 2px !important;
      }
    }
  }

  &::v-deep.failed-validation {
    .v-tabs__item {
      font-weight: bold;
      color: $error;
    }
  }

  .icon-button-close {
    width: 8px;
    height: 8px;
    visibility: hidden;

    &::v-deep .v-icon {
      font-size: 16px;
    }
  }

  &:hover .icon-button-close {
    visibility: visible;
  }

  .active-error {
    color: $error;
  }
}

.new-customer-container {
  align-content: center;
  justify-content: center;
}

.quote-totals {
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba($black-base, 0.2),
    0 3px 10px 0 rgba($black-base, 0.19);

  max-width: 1600px;
  margin: 20px auto;
}

.results {
  margin-top: 40px;
  padding: 0 30px 10px;
}

.info-padded {
  padding-left: 12px;
}

.error-message {
  margin: 10px;
  font-size: 12px;
  color: $error !important;
}

.error-message-user {
  margin: 10px;
  padding-top: 20px;
  font-size: 14px;
  color: $error !important;
  padding-left: 12px;
}

.error-message-bottom {
  margin-right: 20px;
  font-size: 12px;
  color: $error !important;
}

.button-group {
  margin-top: 20px;

  :first-child {
    margin: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  :last-child {
    margin: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.error-non-selected {
  color: $error !important;
}

.notes-button {
  padding-top: 20px;
  margin-right: -8px;
}

.tab-icon-close {
  margin-top: -2px;
}

.quote-totals-save-actions {
  margin-top: -6px;
  margin-right: 8px;
}

.notes-actions {
  padding-right: 8px;
}

::v-deep.v-card__title {
  color: $white;
  background-color: $blue !important;
}

.v-card {
  border-radius: 10px;
}

.past-quotes-label {
  font-size: 23px;
}

.free-quote-warning {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: $gray-light;
  border-radius: 6px;
}

.action-button {
  padding: 5px 15px;
}

.form-options {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .decision-date {
    max-width: 156px;
    max-height: 45px;
    margin-top: 6px;
    margin-right: 8px;
  }

  .switch-right.v-input {
    flex: 0 1 auto;
    justify-content: flex-end;
    padding-right: 1em;

    ::v-deep &__slot {
      justify-content: flex-end;
      padding-right: 1em;
    }
  }
}

.timestamp {
  font-size: 12px;

  .label {
    font-weight: bold;
  }
}

.account-executive-autocomplete ::v-deep .v-input__control {
  min-height: 44px;
}

.checkout {
  align-items: center;
  padding-top: 15px;
  margin-left: 20px;

  .tooltip-position {
    margin-bottom: 18px;
    margin-left: 5px;

    i {
      padding-bottom: 1px;
      font-size: 16px;
      color: $gray-mid-light !important;
    }
  }
}

.pdf-checkbox::v-deep .v-input__control .v-input__slot {
  background-color: transparent !important;
}

.add-quote-container::v-deep .quote-managed-id .cr-input i {
  color: $gray-mid-light !important;
}

.trip-detail {
  margin: 0;
}

.lead-temp-follow-up {
  background-color: $white;
  border-radius: 10px;
  padding: 20px 25px;
  max-width: 1600px;
  margin: 20px auto;
}
.sheet {
  max-width: 1600px;
  margin: 20px auto;
}

.trip-details-button {
  border-radius: 5px !important;
  .v-btn {
    border: 1px solid $gray-light;
    border-radius: 0 !important;
  }
  :first-child {
    border-radius: 5px 0 0 5px !important;
  }
  :last-child {
    border-radius: 0 5px 5px 0 !important;
  }
}

.quote-email-customization {
  max-width: 1600px;
  margin: 25px auto;
}

::v-deep .quote-email-customization {
  box-shadow: none;

  > li.v-expansion-panel__container {
    border-radius: 10px;
  }
}

.top-section {
  margin: 0 auto 20px;
}

.lead-overview {
  border: 1px solid $gray-light;

  &-column {
    padding: 10px;

    strong,
    span {
      display: inline-block;
    }

    strong {
      margin-right: 0.5em;
      text-align: right;
    }
  }

  &-trips {
    text-align: center;
    border-top: 1px solid $gray-light;

    > * {
      padding: 10px;

      &:not(:last-child) {
        border-right: 1px solid $gray-light;
      }
    }
  }

  &.smaller-size {
    span,
    strong,
    .referred-to-column {
      width: auto;
      text-align: left;
    }
  }
}
::v-deep .raised-tool-tip {
  z-index: 5001;
}

@media only screen and (max-width: 960px) {
  .deleted-warning {
    margin: 18px 0px 0px 0px;
  }
}
</style>
